import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCartItem } from "../actions/cartActions";

const GiftInput = ({ item }) => {
  const dispatch = useDispatch();
  const [giftWrapText, setGiftWrapText] = useState(item.message || "");
  const [giftWrap, setGiftWrap] = useState(item.giftWrap || false);

  const handleEngravingInputChange = (e) => {
    const value = e.target.value;
    setGiftWrapText(value);
  };

  const handleUpdateClick = () => {
    dispatch(
      updateCartItem(
        item?.product?._id,
        item?.variant,
        item?.qty,
        item?.sizeMen,
        item?.sizeWomen,
        giftWrap,
        giftWrapText,
        item?.engraveMen, // Use the local state for men's engraving
        item?.engraveWomen // Keep the existing engraving for women
      )
    );
  };

  return (
    <div>
      <div className="flex flex-col gap-4">
        <label className="flex items-center space-x-2 mb-2">
          <input
            type="checkbox"
            checked={giftWrap}
            onChange={() => setGiftWrap(!giftWrap)}
          />
          <span>Add a gift wrap and a message (+ ₹100)</span>
        </label>
        {giftWrap && (
          <>
            <textarea
              value={giftWrapText} // Controlled input using local state for men
              className="border px-2 py-1 rounded"
              placeholder="Enter gift message"
              onChange={(e) => handleEngravingInputChange(e)} // Handle local input change for men
            />
            <button
              onClick={() => handleUpdateClick()}
              className="bg-blue-500 w-1/3 text-white px-2 py-1 rounded"
            >
              Add
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default GiftInput;
