// import {
//   CART_ADD_ITEM,
//   CART_ADD_ITEM_RESET,
//   CART_REMOVE_ITEM,
//   CART_REMOVE_ITEM_RESET,
//   CART_RESET_ITEM,
//   CART_SAVE_PAYMENT_METHOD,
//   CART_SAVE_SHIPPING_ADDRESS,
//   UPDATE_CART_ITEMS,
// } from "../constants/cartConstants";

// export const cartReducer = (state = { cartItems: [] }, action) => {
//   switch (action.type) {
//     // case CART_ADD_ITEM:
//     //   const item = action.payload;
//     //   const existingItemIndex = state.cartItems
//     //     // .filter((item) => !item.customized)
//     //     .findIndex((i) => i.product === item.product && i.size === item.size);

//     //   if (existingItemIndex !== -1) {
//     //     // Item with the same product and size exists, update its quantity or any other property
//     //     const updatedCartItems = [...state.cartItems];
//     //     updatedCartItems[existingItemIndex] = item;

//     //     return { ...state, cartItems: updatedCartItems, successAdd: true };
//     //   } else {
//     //     // Item with a different size, add it to the cart
//     //     return {
//     //       ...state,
//     //       cartItems: [...state.cartItems, item],
//     //       successAdd: true,
//     //     };
//     //   }
//     case CART_ADD_ITEM:
//       const itemToAdd = action.payload;
//       const existingItemIndex = state.cartItems.findIndex((i) => {
//         if (i.customized && itemToAdd.customized) {
//           // Check for customizations and user responses if both items are customized
//           const sameProduct = i.product === itemToAdd.product;
//           const sameSize = i.size === itemToAdd.size;
//           const sameUserResponses =
//             JSON.stringify(i.userResponses) ===
//             JSON.stringify(itemToAdd.userResponses);
//           return sameProduct && sameSize && sameUserResponses;
//         } else {
//           // For non-customized items or when adding a new customized item
//           return i.product === itemToAdd.product && i.size === itemToAdd.size;
//         }
//       });

//       if (existingItemIndex !== -1) {
//         // Item with the same details exists, update its quantity or any other property
//         const updatedCartItems = [...state.cartItems];
//         updatedCartItems[existingItemIndex] = itemToAdd;

//         return { ...state, cartItems: updatedCartItems, successAdd: true };
//       } else {
//         // Item is not in cart, add it
//         return {
//           ...state,
//           cartItems: [...state.cartItems, itemToAdd],
//           successAdd: true,
//         };
//       }

//     // case CART_REMOVE_ITEM:
//     //   return {
//     //     ...state,
//     //     cartItems: state.cartItems.filter(
//     //       (item) =>
//     //         !(
//     //           item.product === action.payload.id &&
//     //           item.size === action.payload.size
//     //         )
//     //     ),
//     //     successRemove: true,
//     //   };
//     case CART_REMOVE_ITEM:
//       const { id, size, userResponses: userResponsesToRemove } = action.payload;

//       return {
//         ...state,
//         cartItems: state.cartItems.filter((item) => {
//           if (item.customized) {
//             const customFieldsMatch = item.userResponses.every(
//               (responseToRemove) => {
//                 const responseExists = userResponsesToRemove.some(
//                   (response) => {
//                     return (
//                       response.question === responseToRemove.question &&
//                       response.answer === responseToRemove.answer
//                     );
//                   }
//                 );
//                 return !responseExists;
//               }
//             );
//             return customFieldsMatch;
//           } else {
//             return !(item.product === id && item.size === size);
//           }
//         }),
//         successRemove: true,
//       };
//     case UPDATE_CART_ITEMS:
//       return { ...state, cartItems: action.payload };
//     case CART_RESET_ITEM:
//       localStorage.setItem("cartItems", JSON.stringify([]));
//       return { ...state, cartItems: [] };
//     case CART_ADD_ITEM_RESET:
//       return { ...state, successAdd: false };
//     case CART_REMOVE_ITEM_RESET:
//       return { ...state, successRemove: false };
//     case CART_SAVE_SHIPPING_ADDRESS:
//       return { ...state, shippingAddress: action.payload };
//     case CART_SAVE_PAYMENT_METHOD:
//       return { ...state, paymentMethod: action.payload };
//     default:
//       return state;
//   }
// };

// src/reducers/cartReducers.js
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_UPDATE_ITEM,
  CART_CLEAR_ITEMS,
  CART_RESET_FLAGS,
  CART_SET_SHIPPING_ADDRESS,
} from "../constants/cartConstants";

const initialState = {
  cartItems: [],
  successAdd: false,
  successUpdate: false,
  successRemove: false,
  shippingAddress: {},
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
    case CART_UPDATE_ITEM:
      return {
        ...state,
        successAdd: false,
        successUpdate: true,
        successRemove: false,
        cartItems: action.payload,
      };

    case CART_SET_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: { addressId: action.payload },
      };

    case CART_REMOVE_ITEM:
      return {
        ...state,
        successAdd: false,
        successUpdate: false,
        successRemove: true,
        cartItems: action.payload,
      };

    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      };

    case CART_RESET_FLAGS: // New case for resetting flags
      return {
        ...state,
        successAdd: false,
        successUpdate: false,
        successRemove: false,
      };

    default:
      return state;
  }
};
