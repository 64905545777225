import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  addItemToWishlist,
  getWishlistItems,
  removeItemFromWishlist,
} from "../actions/wishListActions";
import { scrollToTop } from "../components/ScrollToTop";
import { Icon } from "@chakra-ui/react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { WISHLIST_RESET_FLAGS } from "../constants/wishListConstants";

export default function Wishlist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { id: productId } = useParams();
  let qty = searchParams.get("qty");
  let size = searchParams.get("size");

  const notify = (link, content, type) =>
    type(<RouterLink to={link}>{content}</RouterLink>);

  const wishlist = useSelector((state) => state.wishlist);
  const { wishlistItems, successRemove, successAdd } = wishlist;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate("/signup");
    } else {
      dispatch(getWishlistItems());
    }
  }, [dispatch, successRemove, successAdd]);

  // useEffect(() => {
  //   if (successRemove || successAdd) {
  //     // Reset success flags after processing
  //     dispatch({ type: WISHLIST_RESET_FLAGS });
  //   }
  // }, [dispatch, successRemove, successAdd]);

  // useEffect(() => {
  //   scrollToTop();
  //   if (productId) {
  //     dispatch(addItemToWishlist(productId, size));
  //   }
  // }, [dispatch, productId, qty]);

  const removeItemFromWishlistHandler = (id) => {
    dispatch(removeItemFromWishlist(id));
  };

  const addItemToWishlistHandler = (productId) => {
    dispatch(addItemToWishlist(productId));
    notify("/wishlist", "Item Added To Wishlist", toast.success);
  };

  const handleContinue = () => {
    navigate("/");
  };

  return (
    <div className="flex bg-white flex-col gap-2 max-w-full justify-center px-4 md:px-10 pb-4">
      <div className="mt-3">
        <h1 className="text-xl md:text-3xl lg:text-4xl tracking-tight font-semibold leading-8 lg:leading-9 text-gray-800">
          My Wishlist
        </h1>
      </div>
      <div className="mt-4">
        <p className="text-lg md:text-2xl tracking-tight leading-6 text-gray-600">
          {wishlistItems?.length} items
        </p>
      </div>
      <div className="mt-4 lg:mt-6 grid grid-cols-2 lg:grid-cols-5 gap-x-4 lg:gap-x-8 gap-y-4 lg:gap-y-8">
        {wishlistItems?.length === 0 ? (
          <div>Your wishlist is empty</div>
        ) : (
          wishlistItems?.map((product, index) => (
            <div
              key={index}
              className="flex flex-col justify-between relative p-2 group/prod hover:border"
            >
              <RouterLink
                to={`/product/${product.name?.replaceAll(" ", "-")}/${
                  product._id
                }?variant=${
                  product?.variants?.length > 0 &&
                  product?.variants?.filter((variant) => variant.isPrimary)[0]
                    ?._id
                }`}
              >
                <div className="aspect-h-1 aspect-w-1 overflow-hidden bg-white">
                  {product?.variants?.length > 0 ? (
                    <img
                      src={
                        product?.variants?.length > 0 &&
                        product?.variants?.filter(
                          (variant) => variant.isPrimary
                        )[0]?.images[0]
                      }
                      // src="../prodimg1.png"
                      alt={product?.name}
                      loading="lazy"
                      className={
                        "self-center hover:scale-125 transition-transform ease-in object-cover object-center m-auto bg-[#EBEAE5] text-center"
                      }
                    />
                  ) : (
                    <div className="flex h-full w-full bg-gray-100 items-center justify-center">
                      <span>Image</span>
                    </div>
                  )}

                  {/* <img
                                  // src={product?.variants[0]?.images[1]}
                                  src="../prodimg1.png"
                                  alt={product?.category?.name}
                                  loading="lazy"
                                  className={
                                    "invisible group-hover/prod:visible hover:scale-110 hover:ease-in hover:duration-700 object-cover object-center m-auto"
                                  }
                                /> */}
                </div>
                <h3 className="py-1 mt-3 text-center px-2 text-base font-semibold text-gray-800 h-6 overflow-clip truncate">
                  {product.name}
                </h3>
                <h3 className="py-1 mt-3 text-center px-2 text-[14px] text-gray-500 h-6 overflow-clip truncate">
                  {product.description}
                </h3>
                <div className="flex flex-col mt-3 px-2 gap-2 md:gap-4 justify-between items-center">
                  {/* <p
                                  className={`${
                                    product.discountedPercent === 0
                                      ? "hidden"
                                      : "flex"
                                  } text-base text-red-600 font-semibold`}
                                >
                                  -
                                  {(
                                    ((product.price -
                                      product.basePrice) /
                                      product.price) *
                                    100
                                  )?.toFixed(0)}
                                  %
                                </p> */}
                  <div className="hidden group-hover/prod:flex w-full items-center justify-center gap-1 md:gap-3 ">
                    <p className="text-[14px] text-center text-gray-400 line-through">
                      ₹ {product.basePrice?.toLocaleString("en-IN")}
                    </p>
                    <p className="text-[14px] text-center md:text-base font-semibold text-[#000080]">
                      {(
                        ((product.basePrice - product.price) /
                          product.basePrice) *
                        100
                      ).toFixed(0)}
                      %
                    </p>
                  </div>
                  <div className="flex w-full items-center justify-center gap-1 md:gap-3">
                    <p className="text-sm text-center md:text-base font-bold text-gray-900">
                      ₹ {product.price?.toLocaleString("en-IN")}
                    </p>
                  </div>
                </div>
              </RouterLink>
              {/* <div className="flex w-full  absolute z-1 left-0">
                              <p className="text-sm bg-red-200 opacity-90 p-0.5">
                                Pure Silver
                              </p>
                            </div> */}
              <div className="w-full pt-2 bg-white hidden group-hover/prod:block">
                <div className="flex justify-between items-center gap-1 h-[40px]">
                  <button
                    className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                    // onClick={() => {
                    //   onOpen();
                    //   setSelectedProduct(product);
                    // }}
                  >
                    Add to Cart
                  </button>
                  <button
                    className="flex items-center justify-center w-1/4 h-full border border-[#000080] rounded"
                    onClick={() => {
                      wishlistItems?.some((item) => item._id === product._id)
                        ? removeItemFromWishlistHandler(product._id)
                        : addItemToWishlistHandler(product._id);
                    }}
                  >
                    <Icon
                      as={
                        wishlistItems?.some((item) => item._id === product._id)
                          ? AiFillHeart
                          : AiOutlineHeart
                      }
                      w="6"
                      h="6"
                      textColor="#000080"
                      cursor="pointer"
                    />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <div className="flex justify-center w-full px-4 my-10 lg:mb-0">
        <button
          className="flex items-center justify-center w-full md:w-1/5 p-2 md:p-3 text-[#000080] border border-[#000080] rounded-md hover:bg-[#000080] hover:border-[#000080] hover:text-gray-100"
          onClick={handleContinue}
        >
          Continue Shopping
        </button>
      </div>
    </div>
  );
}
