import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";

import { cartReducer } from "./reducers/cartReducer";
import { wishlistReducer } from "./reducers/wishListReducer";
import {
  orderCreateReducer,
  orderDeliverReducer,
  orderDetailsReducer,
  orderListReducer,
  orderMyListReducer,
  orderPayReducer,
  orderStatusReducer,
  orderStatsReducer,
  warrantyReducer,
  WarrantyClaimsListReducer,
} from "./reducers/orderReducer";
import {
  productCreateReducer,
  productDeleteReducer,
  productDetailsReducer,
  productListReducer,
  productReviewCreateReducer,
  productSearchListReducer,
  productUpdateReducer,
  relatedProductListReducer,
  ymalProductListReducer,
} from "./reducers/productReducer";
import {
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
  userOtpValidateReducer,
  teamUserRegisterReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  tokenAuthReducer,
} from "./reducers/userReducer";

import {
  faqCreateReducer,
  faqDeleteReducer,
  faqDetailsReducer,
  faqListReducer,
  faqUpdateReducer,
  supportDetailsReducer,
  supportUpdateReducer,
} from "./reducers/supportFaqReducer";

import {
  heroListReducer,
  heroCreateReducer,
  heroDeleteReducer,
  heroDetailsReducer,
  heroUpdateReducer,
  heroListLatestReducer,
  heroListCollectionReducer,
  heroListFooterReducer,
} from "./reducers/heroReducer";

import {
  couponCreateReducer,
  couponDeleteReducer,
  couponGetReducer,
  couponListReducer,
  couponUpdateReducer,
  couponValidationReducer,
} from "./reducers/couponReducer";

import {
  menuLinkCreateReducer,
  menuLinkDeleteReducer,
  menuLinkDetailsReducer,
  menuLinkListReducer,
  menuLinkUpdateReducer,
} from "./reducers/menuReducer";
import {
  collectionCreateReducer,
  collectionDeleteReducer,
  collectionDetailsReducer,
  collectionListReducer,
  collectionUpdateReducer,
} from "./reducers/collectionReducer";
import {
  categoryCreateReducer,
  categoryDeleteReducer,
  categoryDetailsReducer,
  categoryListReducer,
  categoryUpdateReducer,
  catSubcategoryListReducer,
} from "./reducers/categoryReducer";
import {
  sizeCreateReducer,
  sizeDeleteReducer,
  sizeDetailsReducer,
  sizeListReducer,
  sizeUpdateReducer,
} from "./reducers/sizeReucer";
import {
  styleCreateReducer,
  styleDeleteReducer,
  styleDetailsReducer,
  styleListReducer,
  styleUpdateReducer,
} from "./reducers/stylesReducer";
import {
  subCategoryCreateReducer,
  subCategoryDeleteReducer,
  subCategoryDetailsReducer,
  subCategoryListReducer,
  subCategoryUpdateReducer,
} from "./reducers/subCategoryReducer";
import {
  addressCreateReducer,
  addressDeleteReducer,
  addressDetailsReducer,
  addressListReducer,
  addressUpdateReducer,
} from "./reducers/addressReducers";
import {
  productSetCreateReducer,
  productSetDeleteReducer,
  productSetDetailsReducer,
  productSetListReducer,
  productSetUpdateReducer,
} from "./reducers/productSetReducers";

const reducer = combineReducers({
  tokenAuth: tokenAuthReducer,
  productList: productListReducer,
  relatedProductList: relatedProductListReducer,
  ymalProductList: ymalProductListReducer,
  productSearchList: productSearchListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  cart: cartReducer,
  wishlist: wishlistReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  teamUserRegister: teamUserRegisterReducer,
  userOtpValidate: userOtpValidateReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderStatus: orderStatusReducer,
  orderMyList: orderMyListReducer,
  orderList: orderListReducer,
  orderDeliver: orderDeliverReducer,
  orderStats: orderStatsReducer,
  warranty: warrantyReducer,
  WarrantyClaimsList: WarrantyClaimsListReducer,
  heroList: heroListReducer,
  heroListLatest: heroListLatestReducer,
  heroListCollection: heroListCollectionReducer,
  heroListFooter: heroListFooterReducer,
  faqCreate: faqCreateReducer,
  faqDelete: faqDeleteReducer,
  faqDetails: faqDetailsReducer,
  faqList: faqListReducer,
  faqUpdate: faqUpdateReducer,
  supportDetails: supportDetailsReducer,
  supportUpdate: supportUpdateReducer,
  heroCreate: heroCreateReducer,
  heroDelete: heroDeleteReducer,
  heroDetails: heroDetailsReducer,
  heroUpdate: heroUpdateReducer,
  couponCreate: couponCreateReducer,
  couponDelete: couponDeleteReducer,
  couponUpdate: couponUpdateReducer,
  couponGet: couponGetReducer,
  couponList: couponListReducer,
  couponValidation: couponValidationReducer,
  menuLinkCreate: menuLinkCreateReducer,
  menuLinkDelete: menuLinkDeleteReducer,
  menuLinkDetails: menuLinkDetailsReducer,
  menuLinkList: menuLinkListReducer,
  menuLinkUpdate: menuLinkUpdateReducer,
  collectionCreate: collectionCreateReducer,
  collectionList: collectionListReducer,
  collectionDetails: collectionDetailsReducer,
  collectionUpdate: collectionUpdateReducer,
  collectionDelete: collectionDeleteReducer,
  categoryCreate: categoryCreateReducer,
  categoryList: categoryListReducer,
  categoryDetails: categoryDetailsReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,
  catSubcategoryList: catSubcategoryListReducer,
  sizeList: sizeListReducer,
  sizeDetails: sizeDetailsReducer,
  sizeCreate: sizeCreateReducer,
  sizeUpdate: sizeUpdateReducer,
  sizeDelete: sizeDeleteReducer,
  styleCreate: styleCreateReducer,
  styleList: styleListReducer,
  styleDetails: styleDetailsReducer,
  styleUpdate: styleUpdateReducer,
  styleDelete: styleDeleteReducer,
  subCategoryCreate: subCategoryCreateReducer,
  subCategoryList: subCategoryListReducer,
  subCategoryDetails: subCategoryDetailsReducer,
  subCategoryUpdate: subCategoryUpdateReducer,
  subCategoryDelete: subCategoryDeleteReducer,
  addressCreate: addressCreateReducer,
  addressList: addressListReducer,
  addressDetails: addressDetailsReducer,
  addressUpdate: addressUpdateReducer,
  addressDelete: addressDeleteReducer,
  productSetCreate: productSetCreateReducer,
  productSetList: productSetListReducer,
  productSetDetails: productSetDetailsReducer,
  productSetUpdate: productSetUpdateReducer,
  productSetDelete: productSetDeleteReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const wishlistItemsFromStorage = localStorage.getItem("wishlistItems")
  ? JSON.parse(localStorage.getItem("wishlistItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromLocalStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const paymentMethodFromStorage = localStorage.getItem("paymentMethod")
  ? JSON.parse(localStorage.getItem("paymentMethod"))
  : "ccavenue";

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromLocalStorage,
    paymentMethod: paymentMethodFromStorage,
    successAdd: false,
    successUpdate: false,
    successRemove: false,
  },
  wishlist: {
    wishlistItems: wishlistItemsFromStorage,
    successAdd: false,
    successRemove: false,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

const middlewares = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
