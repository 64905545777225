import { Flex, Image } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Image width="150px" src="../../animat-diamond.gif" />
    </Flex>
  );
};

export default Loader;
