import {
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { ImCheckboxChecked } from "react-icons/im";
import { CiDeliveryTruck } from "react-icons/ci";
import { BsPaypal } from "react-icons/bs";
import { claimWarranty, listMyOrders } from "../actions/orderActions";
import { ORDER_DETAILS_RESET } from "../constants/orderConstants";
import { scrollToTop } from "../components/ScrollToTop";
import { MdOutlinePayment, MdOutlinePending } from "react-icons/md";
import { FcCancel, FcProcess } from "react-icons/fc";
import { AiOutlineFileDone } from "react-icons/ai";
import ClaimForm from "../components/ClaimForm";
import Sidebar from "../components/Sidebar";

export default function OrderList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [claimId, setClaimId] = useState("");
  const [claimOrderItems, setClaimOrderItems] = useState([]);

  const [state, setState] = useState("All");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderMyList = useSelector((state) => state.orderMyList);
  const { loading: loadingOrders, error: errorOrders, data } = orderMyList;

  useEffect(() => {
    scrollToTop();
    if (!userInfo) {
      navigate(`/signup`);
    } else {
      dispatch(listMyOrders(state));
      dispatch({ type: ORDER_DETAILS_RESET });
    }
  }, [dispatch, state]);

  const chooseIcon = (status) => {
    switch (status) {
      case "Received":
        return MdOutlinePending;
      case "Confirmed":
        return FcProcess;
      case "Shipped":
        return CiDeliveryTruck;
      case "Delivered":
        return AiOutlineFileDone;
      case "Cancelled":
        return FcCancel;
      default:
        return "bg-purple-200 text-purple-600";
    }
  };

  const chooseColor = (status) => {
    switch (status) {
      case "Received":
        return "purple.600";
      case "Confirmed":
        return "yellow.600";
      case "Shipped":
        return "green.600";
      case "Delivered":
        return "green.600";
      case "Cancelled":
        return "red.600";
      default:
        return "purple.600";
    }
  };

  const openClaimForm = (id, orderItems) => {
    onOpen();
    setClaimId(id);
    setClaimOrderItems(orderItems);
  };

  function isClaimButtonDisabled(deliveredAt) {
    if (!deliveredAt) {
      return true;
    }

    const deliveryDate = new Date(deliveredAt);
    const currentDate = new Date();

    const timeDifference = currentDate - deliveryDate;

    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    return daysDifference > 90;
  }

  return (
    <div>
      <Sidebar menu="My Orders" />
      <section id="content">
        <main>
          <div className="flex flex-col gap-4 md:gap-8 py-6 bg-white">
            <div className="flex mx-auto">
              <p className="text-lg md:text-2xl font-bold underline underline-offset-8">
                My Orders
              </p>
            </div>

            <ClaimForm
              isOpen={isOpen}
              onClose={onClose}
              order_id={claimId}
              orderItems={claimOrderItems}
              default={claimOrderItems[0]?.product}
            />

            <div className="self-center text-sm md:text-lg text-gray-400 font-semibold md:font-bold text-center mb-2 md:mb-4 px-1">
              Check the status of recent orders, manage returns, and discover
              similar products.
            </div>

            <Tabs variant="soft-rounded" colorScheme="blue" px="2">
              <TabList
                as={Flex}
                justifyContent={{ sm: "start", md: "center" }}
                overflowX={{ sm: "scroll", md: "hidden" }}
              >
                <Tab>
                  <button onClick={(e) => setState("All")} className="text-xs">
                    All
                  </button>
                </Tab>
                <Tab>
                  <button
                    onClick={(e) => setState("Received")}
                    className="text-xs"
                  >
                    Received
                  </button>
                </Tab>
                <Tab>
                  <button
                    onClick={(e) => setState("Confirmed")}
                    className="text-xs"
                  >
                    Confirmed
                  </button>
                </Tab>
                <Tab>
                  <button
                    onClick={(e) => setState("Shipped")}
                    className="text-xs"
                  >
                    Shipped
                  </button>
                </Tab>
                <Tab>
                  <button
                    onClick={(e) => setState("Delivered")}
                    className="text-xs"
                  >
                    Delivered
                  </button>
                </Tab>
                <Tab>
                  <button
                    onClick={(e) => setState("Cancelled")}
                    className="text-xs"
                  >
                    Cancelled
                  </button>
                </Tab>
              </TabList>
              <TabPanels>
                {[0, 1, 2, 3, 4].map((i) => (
                  <TabPanel padding="1">
                    {loadingOrders ? (
                      <div>Loading</div>
                    ) : errorOrders ? (
                      <div>Error</div>
                    ) : (
                      <div className="flex flex-col">
                        {data.map((order, index) => (
                          <div
                            key={index}
                            className="flex flex-col w-full md:w-4/5 self-center border border-gray-300 rounded-md mb-4 md:mb-8"
                          >
                            <div className="flex flex-wrap md:flex-nowrap justify-between p-2 md:p-4 items-center border-b border-gray-200">
                              <div className="flex flex-wrap md:flex-nowrap gap-4 md:gap-8">
                                <div>
                                  <p className="font-semibold">Order Number</p>
                                  <p className="text-sm text-gray-400">
                                    {order.order_id}
                                  </p>
                                </div>
                                <div>
                                  <p className="font-semibold">Date Placed</p>
                                  <p className="text-sm text-gray-400">
                                    {order.createdAt.substring(0, 10)}
                                  </p>
                                </div>
                                <div>
                                  <p className="font-semibold">Total Amount</p>
                                  <p className="text-sm font-semibold">
                                    ₹{order.totalPrice}
                                  </p>
                                </div>
                              </div>
                              <div className="flex gap-8">
                                <RouterLink to={`/order/${order._id}`}>
                                  <button className="mt-4 md:mt-0 bg-blue-700 text-white p-2 rounded-lg text-sm md:text-base hover:bg-blue-500">
                                    View Order
                                  </button>
                                </RouterLink>
                                <button
                                  className={`mt-4 md:mt-0 bg-blue-700 text-white p-2 rounded-lg text-sm md:text-base hover:bg-blue-500 ${
                                    order.status !== "Delivered" ||
                                    (order.status === "Delivered" &&
                                      isClaimButtonDisabled(order.deliveredAt))
                                      ? "disabled cursor-not-allowed bg-gray-400 hover:bg-gray-500"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    openClaimForm(order._id, order.orderItems)
                                  }
                                  disabled={
                                    order.status !== "Delivered" ||
                                    (order.status === "Delivered" &&
                                      isClaimButtonDisabled(order.deliveredAt))
                                  }
                                >
                                  Claim Warranty
                                </button>
                              </div>
                            </div>

                            {order.orderItems.map((item, idx) => (
                              <div
                                key={idx}
                                className="flex p-4 md:p-8 justify-between gap-8 border-b border-gray-200"
                              >
                                <div className="w-1/5">
                                  <img
                                    className="rounded-md"
                                    src={item.image}
                                    alt={item.name}
                                  />
                                </div>
                                <div className="flex w-full overflow-clip flex-col md:flex-row justify-start md:justify-between md:gap-2">
                                  <h3 className="text-base font-semibold text-gray-800 h-6 w-full overflow-clip md:overflow-visible truncate">
                                    {item.name}
                                  </h3>
                                  <div className="self-start md:self-end font-semibold">
                                    ₹{item.price}
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div className="flex flex-wrap md:flex-nowrap p-2 md:p-4 items-center justify-between">
                              {order.isPaid ? (
                                <div className="flex gap-2">
                                  <div className="flex gap-1 items-center">
                                    <Icon
                                      as={ImCheckboxChecked}
                                      color="green.400"
                                      className="rounded-full"
                                    />
                                    Paid on,
                                  </div>
                                  <div className="font-semibold">
                                    {new Date(order.paidAt).toDateString()}
                                  </div>
                                </div>
                              ) : (
                                <div className="flex gap-1 items-center">
                                  <Icon
                                    as={MdOutlinePayment}
                                    color="blue.400"
                                    boxSize="5"
                                  />{" "}
                                  Please Complete Payment.
                                </div>
                              )}
                              <div className="">
                                <div className="flex gap-1 items-center">
                                  <Icon
                                    as={chooseIcon(order.status)}
                                    boxSize="5"
                                    textColor={chooseColor(order.status)}
                                  />
                                  Your order is {order.status}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </div>
        </main>
      </section>
    </div>
  );
}
