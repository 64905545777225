// src/components/AddressSelection.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getUserAddresses,
  createAddress,
  updateAddress,
  removeAddress,
} from "../actions/addressActions";
import { CiSquareRemove } from "react-icons/ci";
import { setShippingAddress } from "../actions/cartActions";

const AddressSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newAddress, setNewAddress] = useState({}); // State for new address form
  const [editingAddressId, setEditingAddressId] = useState(null); // Track editing state
  const [showForm, setShowForm] = useState(false);
  const [shippingAddId, setShippingAddId] = useState(null);

  const addressList = useSelector((state) => state.addressList);
  const { addresses } = addressList;

  useEffect(() => {
    dispatch(getUserAddresses());
  }, [dispatch]);

  const handleAddAddress = () => {
    dispatch(createAddress(newAddress));
    setNewAddress({}); // Clear form
  };

  const handleEditAddress = (addressId) => {
    const addressToEdit = addresses.find((addr) => addr._id === addressId);
    setNewAddress(addressToEdit);
    setEditingAddressId(addressId);
  };

  const handleUpdateAddress = () => {
    dispatch(updateAddress(editingAddressId, newAddress));
    setEditingAddressId(null);
    setNewAddress({});
  };

  const handleRemoveAddress = (addressId) => {
    dispatch(removeAddress(addressId));
  };

  const handlePlaceOrder = (addressId) => {
    // Save the selected address in the cart state
    setShippingAddId(addressId);
    dispatch(setShippingAddress(addressId));
  
    // Navigate to the payment page
    // navigate("/payment");
  };

  return (
    <div className="container mx-auto p-6 bg-white">
      <h2 className="text-2xl mb-4">Select Shipping Address</h2>
      {addresses?.length === 0 ? (
        <p>No addresses found</p>
      ) : (
        <div className="grid grid-cols-2 gap-10">
          <div>
            {addresses?.map((address) => (
              <div
                key={address._id}
                className={`flex relative items-center gap-6 my-4 p-4 border-2 ${shippingAddId === address._id ? 'border-[#000080]' : 'border-gray-100'}`}
              >
                <div className="flex flex-col text-[12px] font-semibold gap-2">
                  <h3>{address.label}</h3>
                  <div>{address.name}</div>
                  <div>{address.number}</div>
                  <div>{address.address}</div>
                  <div>
                    {address.city}, {address.state}, {address.postalCode}
                  </div>
                  <div>{address.country}</div>
                  <div>{address.isDefault ? "Default Address" : ""}</div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        handleEditAddress(address._id);
                        setShowForm(!showForm);
                      }}
                      className="px-2 py-1 border"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handlePlaceOrder(address._id)}
                      className="px-2 py-1 border"
                    >
                      Deliver Here
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => handleRemoveAddress(address._id)}
                  className="absolute top-4 right-4 px-2 py-1"
                >
                  <CiSquareRemove className="w-6 h-6" />
                </button>
              </div>
            ))}
            <button onClick={() => setShowForm(!showForm)} className="font-bold">
            Add New Address
          </button>
          </div>
          
          {showForm && (
            <div className="flex w-full flex-col gap-6">
              <input
                type="text"
                placeholder="Label"
                value={newAddress.label || ""}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, label: e.target.value })
                }
                className="border p-2 rounded"
              />
              <input
                type="text"
                placeholder="Name"
                value={newAddress.name || ""}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, name: e.target.value })
                }
                className="border p-2 rounded"
              />
              <input
                type="text"
                placeholder="Phone Number"
                value={newAddress.number || ""}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, number: e.target.value })
                }
                className="border p-2 rounded"
              />
              <textarea
                placeholder="Address"
                value={newAddress.address || ""}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, address: e.target.value })
                }
                className="border p-2 rounded"
              />
              <input
                type="text"
                placeholder="City"
                value={newAddress.city || ""}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, city: e.target.value })
                }
                className="border p-2 rounded"
              />
              <input
                type="text"
                placeholder="State"
                value={newAddress.state || ""}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, state: e.target.value })
                }
                className="border p-2 rounded"
              />
              <input
                type="text"
                placeholder="Postal Code"
                value={newAddress.postalCode || ""}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, postalCode: e.target.value })
                }
                className="border p-2 rounded"
              />
              <input
                type="text"
                placeholder="Country"
                value={newAddress.country || ""}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, country: e.target.value })
                }
                className="border p-2 rounded"
              />
              <button
                onClick={
                  editingAddressId ? handleUpdateAddress : handleAddAddress
                }
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                {editingAddressId ? "Update Address" : "Add Address"}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressSelection;
