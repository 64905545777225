import { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
  Button,
  Icon,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { listSearchProducts } from "../actions/productActions";
import { useTranslation } from "react-i18next";
import { addItemToCart } from "../actions/cartActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Pagination from "../components/Pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import {
  addItemToWishlist,
  removeItemFromWishlist,
} from "../actions/wishListActions";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const sortOptions = [
  { name: "Newest", href: "#", current: false },
  { name: "Price: Low to High", href: "#", current: false },
  { name: "Price: High to Low", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Search() {
  const filters = [
    {
      id: "category",
      name: "Category",
      options: [
        { value: "true", label: "Rings", checked: false },
        { value: "true", label: "Earrings", checked: false },
        { value: "true", label: "Pendants", checked: false },
      ],
    },
    {
      id: "color",
      name: "Plating",
      options: [
        { value: "true", label: "Yellow Gold", checked: false },
        { value: "true", label: "White Gold", checked: false },
        { value: "true", label: "Rose Gold", checked: false },
      ],
    },
    {
      id: "priceRange",
      name: "Price",
      options: [
        { value: "true", label: "Upto 2000", checked: false },
        { value: "true", label: "2000-3000", checked: false },
        { value: "true", label: "3000-5000", checked: false },
        { value: "true", label: "5000-8000", checked: false },
        { value: "true", label: "8000 and above", checked: false },
      ],
    },
    {
      id: "gender",
      name: "Gender",
      options: [
        { value: "true", label: "Women", checked: false },
        { value: "true", label: "Men", checked: false },
        { value: "true", label: "Unisex", checked: false },
      ],
    },
    {
      id: "bestOfSapphire",
      name: "Only For You",
      options: [{ value: "true", label: "Only For You", checked: false }],
    },
    {
      id: "latestArrivals",
      name: "Latest Arrivals",
      options: [{ value: "true", label: "Latest Arrivals", checked: false }],
    },
    {
      id: "giftingspecial",
      name: "Gifting Special",
      options: [{ value: "true", label: "Gifting Special", checked: false }],
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const queryParams = new URLSearchParams(routePath.search);
  let query = queryParams.get("q");

  const [stockCount, setStockCount] = useState(0);
  const [qty, setQty] = useState(0);
  const [id, setId] = useState("");
  const [size, setSize] = useState("0");
  const [sizes, setSizes] = useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [category, setCategory] = useState([]); // Use an array for multi-select
  const [subCategory, setSubCategory] = useState([]);
  const [bestOfSapphire, setBestOfSapphire] = useState([]);
  const [latest, setLatest] = useState([]);
  const [gift, setGift] = useState([]);
  const [gender, setGender] = useState([]);
  const [color, setColor] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [sort, setSort] = useState("");
  const [sentToCart, setSentToCart] = useState(false);

  const [selectedPlating, setSelectedPlating] = useState([]);

  const [itemsPerPage, setItemsPerPage] = useState(40);

  const [scrolling, setScrolling] = useState(false);

  const [page, setPage] = useState(1);

  useEffect(() => {
    onTop();
  }, [query]);

  const handlePage = (page) => {
    onTop();
    setPage(page);
  };

  const {
    loading: loadingFilter,
    error: errorFilter,
    menuLinks: menuFilter,
  } = useSelector((state) => state.menuLinkList);

  const productList = useSelector((state) => state.productList);
  const { loading, products, error } = productList;

  const productSearchList = useSelector((state) => state.productSearchList);
  const {
    loading: loadingSearch,
    error: errorSearch,
    products: productSearch,
  } = productSearchList;

  const wishList = useSelector((state) => state.wishlist);
  const { wishlistItems } = wishList;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { i18n } = useTranslation();

  let lang = i18n.language;

  const handleScroll = () => {
    if (window.innerWidth >= 1080 && window.scrollY > 100) {
      setScrolling(true);
    } else if (window.innerWidth <= 400 && window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const clearFilter = () => {
    setCategory([]);
    setSubCategory([]);
    setBestOfSapphire([]);
    setLatest([]);
    setColor([]);
    setPriceRange([]);
    setSort("");
  };

  const handleAddToCart = () => {
    if (qty !== 0) {
      dispatch(addItemToCart(qty, size, id, "en"));
      onClose();
      if (sentToCart) {
        navigate("/cart");
      } else {
        notify("/cart", "Item Added To Cart", toast.success);
      }
    } else {
      notify("#", "Please select size & quantity", toast.error);
    }
  };

  const handleAddToCart2 = (pId) => {
    dispatch(addItemToCart(1, "1", pId, "en"));
    onClose();
    notify("/cart", "Item Added To Cart", toast.success);
  };

  const addItemToWishlistHandler = (productId) => {
    dispatch(addItemToWishlist(productId));
    notify("/wishlist", "Item Added To Wishlist", toast.success);
  };

  const removeItemFromWishlistHandler = (id) => {
    dispatch(removeItemFromWishlist(id));
    notify("/wishlist", "Item Removed from Wishlist", toast.error);
  };

  const handleSizeChange = (e) => {
    setQty(0);
    const selectedSize = e.target.value;
    setSize(selectedSize);
    const selectedSizeObj = sizes.find(
      (sizeObj) =>
        (i18n.language === "de" ? sizeObj.germanSize : sizeObj.indianSize) ===
        selectedSize
    );
    setStockCount(selectedSizeObj ? selectedSizeObj.countInStock : 0);
  };

  const handleQuantityChange = (e) => {
    const selectedQuantity = Number(e.target.value);
    if (selectedQuantity > 0) {
      setQty(selectedQuantity);
    }
  };

  const handleModalOpen = (sizes, id, sent) => {
    onOpen();
    setSentToCart(sent);
    setSizes(sizes);
    setId(id);
  };

  const handleCheckboxChange = (event, setStateCallback) => {
    const { value, checked, name } = event.target;

    setPage(1);

    setStateCallback((prevValues) => {
      if (
        name === "Only For You" ||
        name === "Latest Arrivals" ||
        name === "Gifting Special"
      ) {
        if (checked) {
          return [...prevValues, value];
        } else {
          return prevValues.filter((item) => item !== value);
        }
      } else {
        if (checked) {
          return [...prevValues, name];
        } else {
          return prevValues.filter((item) => item !== name);
        }
      }
    });
  };

  const notify = (link, content, type) =>
    type(<RouterLink to={link}>{content}</RouterLink>);

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  function filterProducts() {
    const filteredProducts = productSearch?.filter((product) => {
      if (category.length > 0 && !category.includes(product.category)) {
        return false;
      }
      if (color.length > 0 && !color.includes(product.plating)) {
        return false;
      }
      if (priceRange.length > 0) {
        const priceRangeValue = parseInt(product.discountedPrice);
        return priceRange.some((selectedRange) => {
          if (selectedRange === "Upto 2000") {
            return priceRangeValue <= 2000;
          } else if (selectedRange === "2000-3000") {
            return priceRangeValue >= 2000 && priceRangeValue <= 3000;
          } else if (selectedRange === "3000-5000") {
            return priceRangeValue >= 3000 && priceRangeValue <= 5000;
          } else if (selectedRange === "5000-8000") {
            return priceRangeValue >= 5000 && priceRangeValue <= 8000;
          } else if (selectedRange === "8000 and above") {
            return priceRangeValue >= 8000;
          }
          return false;
        });
      }

      if (gender.length > 0 && !gender.includes(product.gender)) {
        return false;
      }
      if (
        bestOfSapphire.length > 0 &&
        !bestOfSapphire.includes(product.bestOfSapphire?.toString())
      ) {
        return false;
      }
      if (
        latest.length > 0 &&
        !latest.includes(product.latestArrivals?.toString())
      ) {
        return false;
      }
      if (gift.length > 0 && !gift.includes(product.gift?.toString())) {
        return false;
      }
      return true;
    });

    if (sort === "Newest") {
      filteredProducts.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else if (sort === "Price: Low to High") {
      filteredProducts.sort((a, b) => a.discountedPrice - b.discountedPrice);
    } else if (sort === "Price: High to Low") {
      filteredProducts.sort((a, b) => b.discountedPrice - a.discountedPrice);
    }

    return filteredProducts?.slice(indexOfFirstItem, indexOfLastItem);
  }
  const currentItems = filterProducts();

  useEffect(() => {
    dispatch(listSearchProducts(query));
  }, [query]);

  return (
    <div className="bg-white">
      <div>
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <form className="mt-4 border-t border-gray-200">
                    {menuFilter
                      .filter((menu) => menu.name === "Generic")
                      .map((menu, index) => (
                        <div key={index}>
                          {menu.sublinks?.map((menuObj, index) => (
                            <Disclosure
                              key={index}
                              defaultOpen
                              as="div"
                              className="border-b border-gray-200"
                            >
                              {({ open }) => (
                                <>
                                  <h3 className="flow-root">
                                    <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                                      <span className="text-md font-semibold text-gray-900">
                                        {menuObj.Head}
                                      </span>
                                      <span className="ml-6 flex items-center">
                                        {open ? (
                                          <MinusIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <PlusIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </span>
                                    </Disclosure.Button>
                                  </h3>
                                  <Disclosure.Panel className="py-4 px-2 bg-white">
                                    <div className="space-y-4">
                                      {menuObj.sublinkObj?.map(
                                        (option, idx) => (
                                          <div
                                            key={idx}
                                            className="flex items-center"
                                          >
                                            <input
                                              id={option.name}
                                              name={option.name}
                                              defaultValue={option.name}
                                              type="checkbox"
                                              checked={
                                                menuObj.Head === "Purpose"
                                                  ? subCategory.includes(
                                                      option.name
                                                    )
                                                  : menuObj.Head === "Plating"
                                                  ? color.includes(option.name)
                                                  : menuObj.Head === "Category"
                                                  ? category.includes(
                                                      option.name
                                                    )
                                                  : menuObj.Head === "Gender"
                                                  ? gender.includes(option.name)
                                                  : priceRange.includes(
                                                      option.name
                                                    )
                                              }
                                              onChange={(e) => {
                                                if (
                                                  menuObj.Head === "Purpose"
                                                ) {
                                                  handleCheckboxChange(
                                                    e,
                                                    setSubCategory
                                                  );
                                                } else if (
                                                  menuObj.Head === "Plating"
                                                ) {
                                                  handleCheckboxChange(
                                                    e,
                                                    setColor
                                                  );
                                                } else if (
                                                  menuObj.Head === "Price"
                                                ) {
                                                  handleCheckboxChange(
                                                    e,
                                                    setPriceRange
                                                  );
                                                } else if (
                                                  menuObj.Head === "Category"
                                                ) {
                                                  handleCheckboxChange(
                                                    e,
                                                    setCategory
                                                  );
                                                } else if (
                                                  menuObj.Head === "Gender"
                                                ) {
                                                  handleCheckboxChange(
                                                    e,
                                                    setGender
                                                  );
                                                }
                                              }}
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label
                                              htmlFor={option.name}
                                              className="ml-3 text-sm text-gray-600"
                                            >
                                              {option.name}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          ))}
                        </div>
                      ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div
          className={`flex w-full bg-white items-baseline justify-between border-b border-gray-200 px-2 md:px-14 py-4 ${
            scrolling ? "fixed z-10 top-[48px] md:top-[60px]" : ""
          }`}
        >
          <form className="hidden lg:flex w-full">
            <div className="flex w-full gap-4">
              {menuFilter
                .filter((menu) => menu.name === "Generic")
                .map((menu, index) => (
                  <div className="flex gap-4" key={index}>
                    {menu.sublinks?.map((menuObj, index) => (
                      <Popover key={index} as="div" className="">
                        {({ open }) => (
                          <>
                            <h3 className="flow-root">
                              <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                                <span className="text-md font-semibold text-gray-900">
                                  {menuObj.Head}
                                </span>
                                <span className="ml-2 mr-4 flex items-center">
                                  {open ? (
                                    <IoIosArrowUp
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <IoIosArrowDown
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Popover.Button>
                            </h3>
                            <Popover.Panel className="absolute z-20 py-4 px-2 bg-white border shadow-md">
                              <div className="space-y-4">
                                {menuObj.sublinkObj?.map((option, idx) => (
                                  <div key={idx} className="flex items-center ">
                                    <input
                                      id={option.name}
                                      name={option.name}
                                      defaultValue={option.name}
                                      type="checkbox"
                                      checked={
                                        menuObj.Head === "Purpose"
                                          ? subCategory.includes(option.name)
                                          : menuObj.Head === "Plating"
                                          ? color.includes(option.name)
                                          : menuObj.Head === "Category"
                                          ? category.includes(option.name)
                                          : menuObj.Head === "Gender"
                                          ? gender.includes(option.name)
                                          : priceRange.includes(option.name)
                                      }
                                      onChange={(e) => {
                                        if (menuObj.Head === "Purpose") {
                                          handleCheckboxChange(
                                            e,
                                            setSubCategory
                                          );
                                        } else if (menuObj.Head === "Plating") {
                                          handleCheckboxChange(e, setColor);
                                        } else if (menuObj.Head === "Price") {
                                          handleCheckboxChange(
                                            e,
                                            setPriceRange
                                          );
                                        } else if (
                                          menuObj.Head === "Category"
                                        ) {
                                          handleCheckboxChange(e, setCategory);
                                        } else if (menuObj.Head === "Gender") {
                                          handleCheckboxChange(e, setGender);
                                        }
                                      }}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={option.name}
                                      className="ml-3 text-sm text-gray-600"
                                    >
                                      {option.name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>
                    ))}
                  </div>
                ))}
            </div>
          </form>

          <div className="flex w-full md:w-auto justify-end items-center">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option, idx) => (
                      <Menu.Item key={idx}>
                        {({ active }) => (
                          <button
                            onClick={() => setSort(option.name)}
                            className={classNames(
                              option.current
                                ? "font-medium text-gray-900"
                                : "text-gray-500",
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            {option.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <button
              type="button"
              className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
              onClick={() => {
                setToggle(!toggle);
                setItemsPerPage(toggle ? 40 : 40);
              }}
            >
              <span className="sr-only">View grid</span>
              <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              onClick={() => {
                setMobileFiltersOpen(true);
              }}
            >
              <span className="sr-only">Filters</span>
              <FunnelIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <main id="prodPage" className="mx-auto max-w-7xl">
          <section aria-labelledby="products-heading" className="pb-6 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="md:min-h-[112vh]">
              <div className="">
                <div className="bg-white">
                  <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="sr-only">Products</h2>

                    {loadingSearch ? (
                      <div>
                        <Loader />
                      </div>
                    ) : errorSearch ? (
                      <div>
                        <Message type="error">{errorSearch}</Message>
                      </div>
                    ) : (
                      <div
                        className={`grid mt-4 gap-x-3 gap-y-3 xl:gap-y-6 xl:gap-x-6 ${
                          toggle
                            ? "grid-cols-1 md:grid-cols-4"
                            : "grid-cols-2 md:grid-cols-5"
                        }`}
                      >
                        {currentItems?.map((product, index) => (
                          <div
                            key={index}
                            className="flex flex-col justify-between relative p-2 group/prod hover:border"
                          >
                            <RouterLink
                              to={`/product/${product.name?.replaceAll(
                                " ",
                                "-"
                              )}/${product._id}?variant=${
                                product?.variants?.length > 0 &&
                                product?.variants?.filter((variant) =>
                                  selectedPlating.length > 0
                                    ? selectedPlating.includes(variant.color)
                                    : variant.isPrimary
                                )[0]?._id
                              }`}
                            >
                              <div className="aspect-h-1 aspect-w-1 overflow-hidden bg-white">
                                {product?.variants?.length > 0 ? (
                                  <img
                                    src={
                                      product?.variants?.length > 0 &&
                                      product?.variants?.filter((variant) =>
                                        selectedPlating.length > 0
                                          ? selectedPlating.includes(
                                              variant.color
                                            )
                                          : variant.isPrimary
                                      )[0]?.images[0]
                                    }
                                    // src="../prodimg1.png"
                                    alt={product?.categories?.name}
                                    loading="lazy"
                                    className={
                                      "self-center hover:scale-125 transition-transform ease-in object-cover object-center m-auto bg-[#EBEAE5] text-center"
                                    }
                                  />
                                ) : (
                                  <div className="flex h-full w-full bg-gray-100 items-center justify-center">
                                    <span>Image</span>
                                  </div>
                                )}

                                {/* <img
                                  // src={product?.variants[0]?.images[1]}
                                  src="../prodimg1.png"
                                  alt={product?.category?.name}
                                  loading="lazy"
                                  className={
                                    "invisible group-hover/prod:visible hover:scale-110 hover:ease-in hover:duration-700 object-cover object-center m-auto"
                                  }
                                /> */}
                              </div>
                              <h3 className="py-1 mt-3 text-center px-2 text-base font-semibold text-gray-800 h-6 overflow-clip truncate">
                                {product.name}
                              </h3>
                              <h3 className="py-1 mt-3 text-center px-2 text-[14px] text-gray-500 h-6 overflow-clip truncate">
                                {product.description}
                              </h3>
                              <div className="flex flex-col mt-3 px-2 gap-2 md:gap-4 justify-between items-center">
                                {/* <p
                                  className={`${
                                    product.discountedPercent === 0
                                      ? "hidden"
                                      : "flex"
                                  } text-base text-red-600 font-semibold`}
                                >
                                  -
                                  {(
                                    ((product.price -
                                      product.basePrice) /
                                      product.price) *
                                    100
                                  )?.toFixed(0)}
                                  %
                                </p> */}
                                <div className="hidden group-hover/prod:flex w-full items-center justify-center gap-1 md:gap-3 ">
                                  <p className="text-[14px] text-center text-gray-400 line-through">
                                    ₹{" "}
                                    {product.basePrice?.toLocaleString("en-IN")}
                                  </p>
                                  <p className="text-[14px] text-center md:text-base font-semibold text-[#000080]">
                                    {(
                                      ((product.basePrice - product.price) /
                                        product.basePrice) *
                                      100
                                    ).toFixed(0)}
                                    %
                                  </p>
                                </div>
                                <div className="flex w-full items-center justify-center gap-1 md:gap-3">
                                  <p className="text-sm text-center md:text-base font-bold text-gray-900">
                                    ₹ {product.price?.toLocaleString("en-IN")}
                                  </p>
                                </div>
                              </div>
                            </RouterLink>
                            {/* <div className="flex w-full  absolute z-1 left-0">
                              <p className="text-sm bg-red-200 opacity-90 p-0.5">
                                Pure Silver
                              </p>
                            </div> */}
                            <div className="w-full pt-2 bg-white hidden group-hover/prod:block">
                              <div className="flex justify-between items-center gap-1 h-[40px]">
                                <button
                                  className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                                  // onClick={() => {
                                  //   onOpen();
                                  //   setSelectedProduct(product);
                                  // }}
                                >
                                  Add to Cart
                                </button>
                                <button
                                  className="flex items-center justify-center w-1/4 h-full border border-[#000080] rounded"
                                  onClick={() =>
                                    addItemToWishlistHandler(product._id)
                                  }
                                >
                                  <Icon
                                    as={AiOutlineHeart}
                                    w="6"
                                    h="6"
                                    textColor="#000080"
                                    cursor="pointer"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                        <Drawer onClose={onClose} isOpen={isOpen} size="lg">
                          <DrawerOverlay />
                          <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>{`Select size and quantity`}</DrawerHeader>
                            <DrawerBody>
                              <Tabs isManual variant="enclosed">
                                <TabList>
                                  <Tab>Size Chart</Tab>
                                  <Tab>How to measure</Tab>
                                </TabList>
                                <TabPanels>
                                  <TabPanel>
                                    <div>
                                      <div className="max-h-72 overflow-y-scroll border">
                                        <table className="w-full table-auto">
                                          <thead>
                                            <tr className="sticky top-0 text-sm md:text-md bg-gray-300">
                                              <th className="px-4 py-2">
                                                Indian Size
                                              </th>
                                              <th className="px-4 py-2">
                                                US Size
                                              </th>
                                              <th className="px-4 py-2">
                                                UK Size
                                              </th>
                                              <th className="px-4 py-2">
                                                Count In Stock
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {sizes.map((sizeData, index) => (
                                              <tr
                                                key={index}
                                                className={
                                                  size === sizeData.indianSize
                                                    ? "font-bold"
                                                    : ""
                                                }
                                              >
                                                <td className="border px-4 py-2 text-sm md:text-md">
                                                  <div className="flex gap-1 items-center">
                                                    <input
                                                      type="radio"
                                                      name="indianSize"
                                                      value={
                                                        sizeData.indianSize
                                                      }
                                                      checked={
                                                        size ===
                                                        sizeData.indianSize
                                                      }
                                                      onChange={
                                                        handleSizeChange
                                                      }
                                                      style={{
                                                        accentColor: "red",
                                                      }}
                                                    />
                                                    {sizeData.indianSize}
                                                  </div>
                                                </td>
                                                <td className="border px-4 py-2 text-sm md:text-md">
                                                  {sizeData.usSize}
                                                </td>
                                                <td className="border px-4 py-2 text-sm md:text-md">
                                                  {sizeData.ukSize}
                                                </td>
                                                <td className="border px-4 py-2 text-sm md:text-md">
                                                  {sizeData.countInStock}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>

                                    {size && (
                                      <div>
                                        <label htmlFor="quantitySelect">
                                          Quantity:
                                        </label>
                                        <Select
                                          id="quantitySelect"
                                          value={qty}
                                          onChange={handleQuantityChange}
                                          required
                                        >
                                          <option value={0}>
                                            select quantity
                                          </option>
                                          {Array.from(
                                            { length: stockCount },
                                            (_, index) => index + 1
                                          ).map((num) => (
                                            <option key={num} value={num}>
                                              {num}
                                            </option>
                                          ))}
                                        </Select>
                                      </div>
                                    )}
                                  </TabPanel>
                                  <TabPanel>
                                    <p className="flex w-full justify-center items-center text-lg font-bold py-8">
                                      If you dont have ring
                                    </p>
                                    <div className="grid grid-cols-2 gap-8">
                                      <div className="flex justify-center items-center">
                                        <img
                                          src="Group+14821.avif"
                                          className="h-[120px]"
                                        />
                                      </div>
                                      <div>
                                        Take a small piece of string or thread
                                        or ribbon. Wrap it around your finger.
                                      </div>

                                      <div className="flex justify-center items-center">
                                        <img
                                          src="Group+14823.avif"
                                          className="h-[120px]"
                                        />
                                      </div>
                                      <div>
                                        Mark the spot where the thread meets.
                                      </div>

                                      <div className="flex justify-center items-center">
                                        <img
                                          src="Group+14837.avif"
                                          className="h-[120px]"
                                        />
                                      </div>
                                      <div>
                                        Measure the length of the string with
                                        your ruler.
                                      </div>
                                    </div>

                                    <p className="flex w-full justify-center items-center pt-8">
                                      --------------------------------------------------------------------------------------------
                                    </p>

                                    <p className="flex w-full justify-center items-center text-lg font-bold pb-8">
                                      If you have a ring
                                    </p>
                                    <div className="grid grid-cols-2 gap-8">
                                      <div className="flex justify-center items-center">
                                        <img
                                          src="Group+14815.avif"
                                          className="h-[120px]"
                                        />
                                      </div>
                                      <div>
                                        1 : Place the ring
                                        <br />2 : Measure the inner diameter
                                        within inner edges of your ring with a
                                        ruler.
                                      </div>
                                    </div>
                                  </TabPanel>
                                </TabPanels>
                              </Tabs>
                              <Button
                                type="button"
                                isDisabled={size === "0"}
                                onClick={() => handleAddToCart()}
                                colorScheme="pink"
                              >
                                Next
                              </Button>
                            </DrawerBody>
                          </DrawerContent>
                        </Drawer>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {loading ? (
            <></>
          ) : error ? (
            <></>
          ) : (
            <Pagination
              page={page}
              setPage={setPage}
              handlePage={handlePage}
              totalItems={productSearch?.length}
              itemsPerPage={itemsPerPage}
            ></Pagination>
          )}
        </main>
      </div>
    </div>
  );
}
