import { Flex } from "@chakra-ui/react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { listSupportDetails } from "../actions/supportFaqActions";
import FooterHero from "./FooterHero";
import { FaPinterest, FaYoutube } from "react-icons/fa";

export default function Footer() {
  const location = useLocation();
  const [display, setDisplay] = useState("flex");

  useEffect(() => {
    location.pathname.split("/")[1] === `dashboard` ||
    location.pathname.split("/")[1] === "notifications" ||
    location.pathname.split("/")[1] === `orders` ||
    location.pathname.split("/")[1] === `order` ||
    location.pathname.split("/")[1] === `addresses` ||
    location.pathname.split("/")[1] === "payments" ||
    location.pathname.split("/")[1] === "profile" ||
    location.pathname.split("/")[1] === "claims"
      ? setDisplay("none")
      : setDisplay("flex");
  }, [location]);

  return (
    <Flex
      as="footer"
      className="bg-white border-t-8 border-[#000080]"
      display={display}
    >
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="hidden w-full gap-2 md:flex-row md:justify-center items-center">
          <div className="text-xl">Download the Sapphire365 App Now</div>

          <div className="text-center text-white w-1/2">
            <div className="flex flex-col md:flex-row gap-2 justify-center w-full">
              <a
                href="https://play.google.com/store/apps/details?id=com.soradisdigital.sapphire365"
                target="_blank"
                className="flex items-center border w-full md:w-1/3 rounded-lg px-4 py-2 mx-2"
              >
                <img
                  src="https://d8v879q3glvao.cloudfront.net/Static_images/888857.png"
                  className="w-7 md:w-8"
                  alt="storelogo"
                />
                <div className="text-left ml-3 text-gray-700">
                  <p className="text-xs">Download on </p>
                  <p className="text-sm md:text-base"> Play Store </p>
                </div>
              </a>
              <a
                href="https://apps.apple.com/in/app/sapphire365/id6470025220"
                target="_blank"
                className="flex items-center border w-full md:w-1/3 rounded-lg px-4 py-2 mx-2"
              >
                <img
                  src="https://d8v879q3glvao.cloudfront.net/Static_images/apple-logo-6-1024x1024-min-min-min+(1).png"
                  className="w-7 md:w-8"
                  alt="storelogo"
                />
                <div className="text-left ml-3 text-gray-700">
                  <p className="text-xs">Download on </p>
                  <p className="text-sm md:text-base"> App Store </p>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="grid grid-col-1 md:grid-cols-5 gap-x-12 gap-y-4 md:gap-y-0 pb-4">
          <div className="col-span-1 md:col-span-2">
            <div className="w-[80%]">
              <a href="#" className="flex w-full">
                <img
                  src="../../navyblue-r@4x.png"
                  className="w-full"
                  alt="Logo"
                />
              </a>
              <FooterHero />
            </div>
          </div>
          <div className="col-span-1 pt-[16%]">
            <p className="text-[16px] font-bold pb-4">Contact:</p>
            <p className="mail text-[14px] text-gray-700 font-semibold mb-5">
              <span>Mail : </span>
              <a href="mailto:support@sairahjewels.com" target="_blank">
                support@sairahjewels.com
              </a>
            </p>
            <p className="flex gap-1 items-center pb-4 text-[14px] text-gray-700 font-semibold">
              <span>Phone : </span>
              <a href="tel:+918828826996">+918828826996</a>
            </p>
          </div>
          <div className="col-span-1 pt-[16%]">
            <h2 className="text-[16px] font-bold pb-4">Quick Links</h2>
            <ul className="font-delius text-[14px] text-gray-700 font-semibold flex flex-col gap-3">
              <li key="1" className="mb-2">
                <RouterLink to={`/orders`}>{"Track Order"}</RouterLink>
              </li>
              <li key="4" className="mb-2">
                <RouterLink to={`/supportFAQ`}>{"Support & FAQs"}</RouterLink>
              </li>
              <li key="5" className="mb-2">
                <a href="../terms-and-conditions.html" target="_blank">
                  {"Terms and Conditions"}
                </a>
              </li>
              <li key="6" className="mb-2">
                <a href="../eula.html" target="_blank">
                  {"End-User License Agreement"}
                </a>
              </li>
              <li key="6" className="mb-2">
                <a href="../shipping-policy.html" target="_blank">
                  {"Shipping Policy"}
                </a>
              </li>
              <li key="7" className="mb-2">
                <a href="../privacy-policy.html" target="_blank">
                  {"Privacy Policy"}
                </a>
              </li>
              <li key="8" className="mb-2">
                <a href="../return-and-refund.html" target="_blank">
                  {"Return & Refund"}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-span-1 pt-[16%]">
            <h2 className="text-[16px] font-bold pb-4">Links</h2>
            <ul className="font-delius text-[14px] text-gray-700 font-semibold flex flex-col gap-3">
              <li key="1" className="mb-2">
                <a href="../disclaimer.html" target="_blank">
                  {"Disclaimer"}
                </a>
              </li>
              <li key="4" className="mb-2">
                <a href="../cookie-policy.html" target="_blank">
                  {"Cookie Policy"}
                </a>
              </li>
              <li key="5" className="mb-2">
                <a href="../sitemap.xml" target="_blank">
                  {"Sitemap"}
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="flex w-full">
          
        </div> */}
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="flex flex-col gap-2"></div>
          <span className="hidden md:flex md:flex-col col-span-2 justify-center items-center font-delius text-[14px] text-gray-500 font-medium sm:text-center">
            <p>
              © 2024 Soradis Jewellers Private Limited. All rights reserved.
            </p>
            <p>
              Designed & Developed by
              <a
                href="https://www.soradisdigital.com"
                target="_blank"
                className="ml-1 text-blue-500"
              >
                Soradis Digital®
              </a>
            </p>
          </span>
          <div className="flex items-center justify-center my-4 space-x-6 sm:mt-0">
            <a
              // href="https://www.facebook.com/yoursapphire365"
              href="#"
              target="_blank"
              className="text-gray-500 hover:text-blue-900"
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Facebook page</span>
            </a>
            <a
              // href="https://www.instagram.com/sapphire_365/"
              href="#"
              target="_blank"
              className="text-gray-500 hover:text-pink-900"
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Instagram page</span>
            </a>
            <a
              href="https://www.pinterest.com/sairah_jewels"
              target="_blank"
              className="text-gray-500 hover:text-red-800"
            >
              {/* <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg> */}
              <FaPinterest className="w-6 h-6" />
              <span className="sr-only">Pinterest</span>
            </a>
            <a
              href="https://www.youtube.com/channel/UCwC_JG-awFssOo4qNB6HKhQ"
              target="_blank"
              className="text-gray-500 hover:text-red-700"
            >
              {/* <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg> */}
              <FaYoutube className="w-6 h-6" />
              <span className="sr-only">YouTube</span>
            </a>
          </div>
          <span className="flex flex-col md:hidden justify-center items-center text-sm text-gray-500 sm:text-center">
            <p className="text-center">
              © 2024 Soradis Jewellers Private Limited. All rights reserved.
            </p>
            <p>
              Designed & Developed by
              <a
                href="https://www.soradisdigital.com"
                target="_blank"
                className="ml-1 text-blue-500"
              >
                Soradis Digital™
              </a>
            </p>
          </span>
        </div>
        <div className="flex w-full items-center justify-center"></div>
      </div>
    </Flex>
  );
}
