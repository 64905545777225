import React, { useState } from "react";
// import { initializeApp } from 'firebase/app';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import OtpInput from "otp-input-react";

import { auth } from "../config/firebase.config";
import { useDispatch } from "react-redux";
import { register } from "../actions/userActions";
import { useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useEffect } from "react";
import { USER_REGISTER_RESET } from "../constants/userConstants";
import Message from "../components/Message";

// const firebaseConfig = {
//   apiKey: "your-api-key",
//   authDomain: "your-auth-domain",
//   projectId: "your-project-id",
//   storageBucket: "your-storage-bucket",
//   messagingSenderId: "your-messaging-sender-id",
//   appId: "your-app-id"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

const RegistrationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    otp: "",
  });

  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [error, setError] = useState("");

  const userRegister = useSelector((state) => state.userRegister);
  const { error: errorUSer, userInfo, success } = userRegister;

  useEffect(() => {
    if (userInfo?.success) {
      navigate("/signup");
    }
    dispatch({ type: USER_REGISTER_RESET });
  }, [navigate, userInfo]);

  const handleInputChange = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const sendOtp = (e) => {
    e.preventDefault();

    if (!form.phone) {
      setError("Please enter a valid phone number");
      return;
    }

    setError("");

    // Initialize RecaptchaVerifier if not already initialized
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow the user to proceed with the OTP request
            console.log("Recaptcha verified");
          },
          "expired-callback": () => {
            // Response expired, reset reCAPTCHA
            console.error("Recaptcha expired");
          },
        },
        auth
      );
    }

    // Send OTP using Firebase
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, form.phone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setOtpSent(true);
        console.log("OTP sent");
      })
      .catch((err) => {
        setError("Error sending OTP: " + err.message);
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (!form.otp) {
      setError("Please enter the OTP");
      return;
    }

    setError("");
    window.confirmationResult
      .confirm(form.otp)
      .then((result) => {
        console.log("otp varification result", result);
        setOtpVerified(true);
        console.log("OTP verified");
      })
      .catch((err) => {
        setError("Invalid OTP: " + err.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otpVerified) {
      setError("Please verify OTP before submitting the form");
      return;
    }

    if (form.password !== form.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    dispatch(
      register(
        form.firstName,
        form.lastName,
        form.email,
        form.phone,
        form.password,
        otpVerified
      )
    );
    // Call your registration API here
    console.log("Registration successful:", form);
  };

  return (
    <div className="flex border-b justify-between gap-10 px-6 sm:px-6 lg:px-8 py-6 bg-white">
      <div className="flex flex-col items-center w-1/2 p-10 space-y-8">
        <div className="flex flex-col w-[90%]">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-3">
            Create Your Account
          </h2>
          <h3 className="font-semibold text-[14px]">
            Please create your SAIRAH Account.
          </h3>
        </div>
        <form className="mt-8 space-y-6 w-[90%]" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4 -space-y-px rounded-md">
            <div>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={form.firstName}
                onChange={(e) => handleInputChange(e.target.value, "firstName")}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={form.lastName}
                onChange={(e) => handleInputChange(e.target.value, "lastName")}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={form.email}
                onChange={(e) => handleInputChange(e.target.value, "email")}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <PhoneInput
                className="w-full pl-2 border border-gray-300 rounded-md"
                defaultCountry="IN"
                international
                placeholder="Phone"
                value={form.phone}
                onChange={(value) => handleInputChange(value, "phone")}
                required
              />
            </div>
            <button
              onClick={sendOtp}
              className="bg-[#000080] text-white py-2 px-4 rounded-md hover:bg-[#000090] w-full"
            >
              {otpSent ? "Resend OTP" : "Send OTP"}
            </button>

            {otpSent && (
              <>
                <div className="mt-4">
                  <OtpInput
                    value={form.otp}
                    onChange={(value) => handleInputChange(value, "otp")}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    className="opt-container w-full px-4 py-2 border border-gray-300 rounded-md"
                  />
                  <button
                    onClick={verifyOtp}
                    className="bg-green-500 text-white py-2 px-4 rounded-md mt-2 w-full hover:bg-green-600"
                  >
                    Verify OTP
                  </button>
                </div>
              </>
            )}

            {otpVerified && (
              <>
                <div>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={form.password}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "password")
                    }
                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={form.confirmPassword}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "confirmPassword")
                    }
                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded-md w-full hover:bg-blue-600"
                >
                  Register
                </button>
              </>
            )}
          </div>
        </form>
        {error && <Message type="error">{error}</Message>}
      </div>

      <div className="flex justify-center items-center">
        <div className="border-l-2 border-gray-300 h-[500px]"></div>
      </div>

      <div className="flex items-center flex-col w-1/2 p-10 space-y-8">
        <div className="flex flex-col gap-10 w-[90%] mb-4">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-3">
            Already have an Account?
          </h2>
          <h3 className="font-semibold text-[14px] leading-10">
            Sign In to access your account, saved items, and order history.
          </h3>
        </div>
        <div className="flex items-center w-[90%]">
          <RouterLink
            to="/signup"
            className="flex w-full justify-center rounded-md bg-[#000080] px-3 py-2 text-sm font-semibold text-white hover:bg-[#000090] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign In
          </RouterLink>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
