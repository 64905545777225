import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { removeFromCart, saveShippingAddress } from "../actions/cartActions";
import CheckoutSteps from "../components/CheckoutSteps";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { scrollToTop } from "../components/ScrollToTop";
import { serverIp } from "../config/conf";
import Loader from "../components/Loader";
import useRazorpay from "react-razorpay";
import Message from "../components/Message";
import { checkCoupon } from "../actions/couponActions";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { createOrder } from "../actions/orderActions";
import {
  ORDER_CREATE_RESET,
  ORDER_DETAILS_RESET,
} from "../constants/orderConstants";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";
import AddressSelection from "../components/AddressSelection";

const ShippingScreen = () => {
  const [Razorpay] = useRazorpay();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routePath = useLocation();
  const queryParams = new URLSearchParams(routePath.search);
  const orderStatus = queryParams.get("orders");
  const orderId = queryParams.get("oid");
  const transactionId = queryParams.get("tid");
  const failmessage = queryParams.get("failmessage");
  const statusmessage = queryParams.get("statusmessage");

  let [isOpen, setIsOpen] = useState(false);

  const cart = useSelector((state) => state.cart);
  const { shippingAddress, cartItems } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadingUser, error: errorUSer, user } = userDetails;

  const couponList = useSelector((state) => state.couponList);
  const {
    loading: loadingCoupon,
    error: errorCouponLoad,
    coupons,
  } = couponList;

  const couponValidation = useSelector((state) => state.couponValidation);
  const { loading, error: errorCoupon, isValid, discount } = couponValidation;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { data: order, success } = orderCreate;

  cart.itemsPrice = cart.cartItems?.reduce(
    (acc, currVal) => acc + currVal.price * +currVal.qty,
    0
  );
  cart.giftWrapTotalCost = cart.cartItems?.reduce(
    (acc, currVal) => acc + (currVal.giftWrap ? currVal.qty * 50 : 0),
    0
  );
  cart.shippingPrice = 0; //cart.itemsPrice < 1000 ? 1000 : 0;
  cart.taxPrice = 0; //(18 * cart.itemsPrice) / 100;
  cart.discount = cart.itemsPrice * (discount / 100);
  cart.totalPrice = (
    cart.itemsPrice +
    cart.shippingPrice +
    cart.taxPrice +
    cart.giftWrapTotalCost -
    cart.discount
  ).toFixed(0);

  const [code, setCode] = useState("");
  const [selectedOption, setSelectedOption] = useState("creditDebitCard");
  const [name, setName] = useState(shippingAddress.name || "");
  const [phone, setPhone] = useState(shippingAddress.phone || "");
  const [email, setEmail] = useState(shippingAddress.email || "");
  const [postalCode, setPostalCode] = useState(
    shippingAddress.postalCode || ""
  );
  const [address, setAddress] = useState(shippingAddress.address || "");
  const [town, setTown] = useState(shippingAddress.town || "");
  const [state, setState] = useState(shippingAddress.state || "");
  const [city, setCity] = useState(shippingAddress.city || "");
  const [country, setCountry] = useState(shippingAddress.country || "India");

  const [selectedAddress, setSelectedAddress] = useState(0); // Initialize with 'home'

  const [formHtml, setFormHtml] = useState("");

  const ccavpaymentcall = async (order) => {
    const formData = new URLSearchParams();
    formData.append("merchant_id", "2770933");
    formData.append("order_id", order.order_id);
    formData.append("currency", "INR");
    formData.append("amount", order.totalPrice);
    formData.append("redirect_url", `${serverIp}/ccavResponseHandler`);
    formData.append("cancel_url", `${serverIp}/ccavResponseHandler`);
    formData.append("language", "EN");
    formData.append("billing_name", order.shippingAddress?.name);
    formData.append("billing_address", order.shippingAddress?.address);
    formData.append("billing_city", order.shippingAddress?.city);
    formData.append("billing_state", order.shippingAddress?.state);
    formData.append("billing_zip", order.shippingAddress?.postalCode);
    formData.append("billing_country", order.shippingAddress?.country);
    formData.append("billing_tel", order.shippingAddress?.phone);
    formData.append("billing_email", order.shippingAddress?.email);
    formData.append("delivery_name", order.shippingAddress?.name);
    formData.append("delivery_address", order.shippingAddress?.address);
    formData.append("delivery_city", order.shippingAddress?.city);
    formData.append("delivery_state", order.shippingAddress?.state);
    formData.append("delivery_zip", order.shippingAddress?.postalCode);
    formData.append("delivery_country", order.shippingAddress?.country);
    formData.append("delivery_tel", order.shippingAddress?.phone);

    try {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const { data } = await axios.post(
        `${serverIp}/ccavRequestHandler`,
        formData,
        config
      );
      setFormHtml(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (success) {
      if (order.paymentMethod === "cod") {
        navigate(
          `/successPay?orders=Success&oid=${order.order_id}&moid=${order._id}&paymentmode=${order.paymentMethod}`
        );
      } 
      // else {
      //   ccavpaymentcall(order);
      // }
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch({ type: CART_CLEAR_ITEMS });
      dispatch({ type: ORDER_DETAILS_RESET });
      dispatch(getUserDetails());
    }
  }, [success, navigate, order]);

  useEffect(() => {
    if (formHtml) {
      const formContainer = document.createElement("div");
      formContainer.innerHTML = formHtml;
      const form = formContainer.querySelector("form");
      document.body.appendChild(form);
      form.submit();
    }
  }, [formHtml]);

  useEffect(() => {
    if (
      orderStatus !== "" &&
      orderStatus !== null &&
      orderStatus !== undefined &&
      orderStatus !== "Success"
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [orderStatus]);

  const handleAddressChange = (addressIndex) => {
    setSelectedAddress(addressIndex);

    if (addressIndex !== null && user.addresses[addressIndex]) {
      const selectedAddress = user.addresses[addressIndex];
      setName(selectedAddress.name);
      setPhone(selectedAddress.number);
      setAddress(selectedAddress.address);
      setPostalCode(selectedAddress.postalCode);
      setTown(selectedAddress.town);
      setState(selectedAddress.state);
      setCity(selectedAddress.city);
      setCountry(selectedAddress.country);
    } else {
      setName("");
      setPhone("");
      setPostalCode("");
      setAddress("");
      setTown("");
      setState("");
      setCity("");
      setCountry("");
    }
  };

  useEffect(() => {
    // scrollToTop();
    if (!userInfo) {
      navigate("/signup");
    } else {
      if (!user?.firstName) {
        dispatch(getUserDetails());
      } else {
        setName(user.firstName);
        setEmail(user.email);
        setPhone(user.phone);
      }
    }
  }, [userInfo, user]);

  // const submitHandler = async () => {

  //   const selectedAddressData = {
  //     address,
  //     town,
  //     city,
  //     state,
  //     postalCode,
  //     country,
  //   };

  //   if (selectedAddress !== "new" && user.addresses[selectedAddress]) {
  //     dispatch(
  //       updateUserProfile({
  //         addresses: user.addresses.map((address, index) =>
  //           index === selectedAddress
  //             ? { ...selectedAddressData, name, number: phone, email }
  //             : { ...address, name, number: phone, email }
  //         ),
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       updateUserProfile({
  //         addresses: [
  //           ...(user.addresses || []),
  //           { ...selectedAddressData, name, number: phone, email },
  //         ],
  //       })
  //     );
  //   }

  //   const cc_order_id = Math.floor(Date.now() * Math.random());

  //   dispatch(
  //     createOrder({
  //       order_id: cc_order_id,
  //       orderItems: cart.cartItems,
  //       shippingAddress: { ...selectedAddressData, name, phone, email },
  //       paymentMethod: selectedOption,
  //       itemsPrice: cart.itemsPrice,
  //       shippingPrice: cart.shippingPrice,
  //       giftWrapTotalPrice: cart.giftWrapTotalCost,
  //       taxPrice: cart.taxPrice,
  //       totalPrice: cart.totalPrice,
  //       couponCode: cart.couponCode,
  //       discount: discount,
  //     })
  //   );
  // };

  const handlePayment = useCallback(async () => {
    // const order = await createOrder(params);

    console.log(cart.totalPrice);
    const minimumAmount = 1.0;
    const validatedTotalPrice = Math.max(cart.totalPrice, minimumAmount);
    const options = {
      // key: "rzp_test_I4PMvx8lJvNsZf",
      key: "rzp_test_Ain4xsWuc4rqYm",
      amount: validatedTotalPrice * 100,
      currency: "INR",
      name: "Sairah",
      description: "Transaction",
      image: "https://sairahjewels.com/logo/png/black/black-r@4x.png",
      order_id: null,
      handler: (res) => {
        // console.log({
        //   order_id: res.razorpay_order_id,
        //   payment_id: res.razorpay_payment_id,
        //   update_time: Date.now(),
        //   email_address: userInfo.email,
        //   razorpay_signature: res.razorpay_signature,
        // });
        dispatch(
          createOrder({
            orderItems: cart.cartItems,
            shippingAddress: cart?.shippingAddress?.addressId,
            paymentMethod: "Razorpay",
            itemsPrice: cart.itemsPrice,
            shippingPrice: cart.shippingPrice,
            giftWrapTotalPrice: cart.giftWrapTotalCost,
            taxPrice: cart.taxPrice,
            totalPrice: cart.totalPrice,
            couponCode: cart.couponCode,
            discount: discount,
            paymentResult: { razorpay_payment_id: res.razorpay_payment_id },
            isPaid:true,
            paidAt: Date.now()

            //         orderItems
            // shippingAddress
            // paymentMethod
            // taxPrice
            // shippingPrice
            // totalPrice
            // couponCode
            // discount
            // giftWrapTotalPrice
            // paymentResult
            // isPaid
            // paidAt
          })
        );
        console.log(res);
      },
      // prefill: {
      //   name: userInfo.firstName,
      //   email: userInfo.email,
      //   contact: userInfo.phone,
      // },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#000080",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  const checkCouponHandler = (e) => {
    e.preventDefault();
    dispatch(checkCoupon(code));
    cart.couponCode = code;
  };

  const [selectedOffer, setSelectedOffer] = useState(null);

  const handleOfferSelection = (code) => {
    const isOfferValid = true;

    if (isOfferValid) {
      setSelectedOffer(code);
      setCode(code);
    }
  };

  return (
    <div className="flex flex-col p-4 md:p-6">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Payment Failed
                  </Dialog.Title>
                  <div className="text-center">
                    <img
                      src="https://d8v879q3glvao.cloudfront.net/Static_images/credit-card+(1).gif"
                      alt="Failure"
                      className="w-1/2 mx-auto"
                    />
                  </div>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Failed Reason - {failmessage}
                    </p>
                    <p className="text-sm text-gray-500">
                      Cancel Reason - {statusmessage}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Retry Payment
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <nav className="flex justify-center pb-4" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <a
              href="#"
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              Cart
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <a
                href="#"
                className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >
                Information
              </a>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                Payment
              </span>
            </div>
          </li>
        </ol>
      </nav>

      <div className="flex flex-col gap-4 justify-between">
        {/* <div className="flex h-full max-h-[135vh] w-full md:w-1/2 flex-col shadow-md">
          <h2 className="text-2xl p-4 font-semibold leading-7 text-gray-900">
            Personal Information
          </h2>
          <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
            <form id="form1" onSubmit={submitHandler}>
              <div className="space-y-8">
                <div className="">
                  <div className="grid grid-cols-1 md:grid-cols-8 gap-x-6 gap-y-8">
                    <div className="md:col-span-8">
                      <label
                        htmlFor="addressType"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Select Address
                      </label>
                      <div className="mt-2">
                        <select
                          id="addressType"
                          name="addressType"
                          value={selectedAddress}
                          onChange={(e) => handleAddressChange(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          {user &&
                            user.addresses &&
                            user.addresses.map((address, index) => (
                              <option key={index} value={index}>
                                {address.type} - {address.address}
                              </option>
                            ))}
                          <option value="new">Add New Address</option>
                        </select>
                      </div>
                    </div>

                    <div className="md:col-span-8">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          type="email"
                          id="Email"
                          required
                          placeholder="Email*"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="flex gap-6 md:col-span-8">
                      <div className="w-1/2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Full name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="name"
                            required
                            placeholder="Name*"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="w-1/2">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Phone
                        </label>
                        <div className="mt-2">
                          <input
                            type="tel"
                            id="Mobile"
                            required
                            placeholder="Mobile No*"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="md:col-span-8">
                      <label
                        htmlFor="street-address"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Street address
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          required
                          placeholder="Address (House No, Building, Street, Area)*"
                          id="address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        City
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          required
                          id="city"
                          placeholder="City / District*"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          autoComplete="address-level2"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        State / Province
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          required
                          id="state"
                          placeholder="State*"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          autoComplete="address-level1"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Country
                      </label>
                      <div className="mt-2">
                        <select
                          id="country"
                          name="country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          autoComplete="country-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value={"India"}>INDIA</option>
                          <option value={"US"}>US</option>
                          <option value={"UK"}>UK</option>
                          <option value={"UAE"}>UAE</option>
                          <option value={"CANADA"}>CANADA</option>
                        </select>
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <label
                        htmlFor="pinCode"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        ZIP / Postal code
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          required
                          id="pinCode"
                          placeholder="Pin Code*"
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="flex flex-col space-y-2 p-6">
            <p>Select Payment Mode -</p>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="creditDebitCard"
                value="creditDebitCard"
                checked={selectedOption === "creditDebitCard"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="creditDebitCard">Credit/Debit Card</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="netBanking"
                value="netBanking"
                checked={selectedOption === "netBanking"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="netBanking">Net Banking</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="upi"
                value="upi"
                checked={selectedOption === "upi"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="upi">UPI</label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="cod"
                value="cod"
                checked={selectedOption === "cod"}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="form-radio h-5 w-5 text-indigo-600"
              />
              <label htmlFor="cod">Cash on Delivery (COD)</label>
            </div>
          </div>
        </div> */}
        <div className="flex h-full w-full flex-col shadow-md">
          <AddressSelection />
        </div>
        <div className="flex h-full w-full flex-col items-center">
          <button
            type="button"
            onClick={() => handlePayment()}
            className="rounded-md w-1/3 bg-[#000080] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#000080] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Proceed to Pay
          </button>
        </div>

        {/* <div className="flex h-full w-full md:w-1/2 flex-col shadow-md">
          <h2 className="text-2xl font-semibold leading-7 p-4 text-gray-900">
            Cart Items
          </h2>
          <div className="flex-1 md:min-h-[35vh] overflow-y-auto px-4 py-6 sm:px-6">
            <div className="">
              <div className="flow-root">
                <ul role="list" className="-my-6 divide-y divide-gray-200">
                  {cartItems.map((item) => (
                    <li key={item.product} className="flex p-4 bg-white">
                      <div className="h-20 md:h-24 w-20 md:w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                        <img
                          src={item.image}
                          alt={item.category}
                          className="h-full w-full object-cover object-center"
                        />
                      </div>

                      <div className="ml-4 flex w-full flex-col overflow-clip">
                        <div className="flex w-full overflow-clip flex-col md:flex-row justify-start md:justify-between md:gap-2">
                          <a
                            className="font-semibold text-gray-800 w-full line-clamp-2"
                            href={`/product/${item.product}`}
                          >
                            {item.name}
                          </a>
                          <div className="self-start font-semibold">
                            ₹{item.price}
                          </div>
                        </div>
                        {item.giftWrap ? (
                          <div>Message : {item.message}</div>
                        ) : (
                          <></>
                        )}
                        {item.customized && (
                          <div className="flex flex-col w-full py-2">
                            {item.userResponses &&
                              item.userResponses?.map((response) => (
                                <div className="flex gap-2 text-sm text-gray-500">
                                  <label>{response.question} - </label>
                                  <h3>{response.answer}</h3>
                                </div>
                              ))}
                          </div>
                        )}
                        <div className="flex flex-1 items-end justify-between text-sm">
                          <div className="flex gap-2">
                            <p className="text-gray-500">Qty {item.qty}</p>
                            <p className="text-gray-500">Size {item.size}</p>
                          </div>

                          <div className="flex">
                            {item.giftWrap ? "Gift Wrap Added" : ""}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="border-t border-white px-4 py-6 md:px-6">
            <div className="container mx-auto">
              <h2 className="text-xl font-semibold mb-2">Available Offers</h2>
              <div className="flex w-full overflow-x-auto gap-4 py-2">
                {loadingCoupon ? (
                  <div>Loading</div>
                ) : errorCouponLoad ? (
                  <div>Error</div>
                ) : (
                  coupons
                    .filter((coupon) => !coupon.isHeading)
                    .map((coupon, idx) => (
                      <div
                        key={idx}
                        className={`w-1/2 md:w-1/4 p-4 border-2 border-gray-300 rounded-lg cursor-pointer ${
                          selectedOffer === coupon.code
                            ? "bg-gray-50 border-[#000080]"
                            : "bg-white"
                        }`}
                        onClick={() => handleOfferSelection(coupon.code)}
                      >
                        <h3 className="text-lg font-semibold">{coupon.name}</h3>
                        <p className="text-base">{coupon.description}</p>
                      </div>
                    ))
                )}
              </div>
            </div>

            {userInfo && (
              <div className="my-2">
                <form id="form2" onSubmit={checkCouponHandler}>
                  <p className="text-lg">Coupon Code</p>
                  <div className="flex gap-2 w-full">
                    <input
                      type="text"
                      id="code"
                      required
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <button
                      type="submit"
                      value="form2"
                      className="w-1/4 bg-[#000080] hover:bg-[#000080] text-white font-medium px-1 md:px-4 py-0.5 md:py-2 rounded"
                    >
                      Apply
                    </button>
                  </div>
                </form>
                {loading && <Loader />}
                {errorCoupon && <Message type="error">{errorCoupon}</Message>}
              </div>
            )}

            {cartItems.length !== 0 ? (
              <div className="h-full">
                <div className="flex justify-between py-2">
                  <p className="text-gray-700">
                    Items (
                    {cart.cartItems?.reduce(
                      (acc, currVal) => acc + currVal.qty,
                      0
                    )}
                    )
                  </p>
                  <p className="text-gray-700">₹{cart.itemsPrice}</p>
                </div>

                <div className="flex justify-between py-2">
                  <p className="text-gray-700">Shipping</p>
                  <p className="text-gray-700">₹{cart.shippingPrice}</p>
                </div>

                <div className="flex justify-between py-2">
                  <p className="text-gray-700">Tax</p>
                  <p className="text-gray-700">₹{cart.taxPrice}</p>
                </div>

                <div className="flex justify-between py-2">
                  <p className="text-gray-700">
                    Gift Wraps (
                    {cart.cartItems?.reduce(
                      (count, currVal) =>
                        count + (currVal.giftWrap ? currVal.qty * 1 : 0),
                      0
                    )}
                    )
                  </p>
                  <p className="text-gray-700">₹{cart.giftWrapTotalCost}</p>
                </div>

                <div className="flex justify-between py-2">
                  <p className="text-gray-700">Discount({discount}%)</p>
                  <p className="text-gray-700">₹{cart.discount?.toFixed(0)}</p>
                </div>

                {isValid && (
                  <div className="flex justify-between py-2">
                    <p className="text-gray-700">Coupon Code</p>
                    <p className="text-gray-700">{cart.couponCode}</p>
                  </div>
                )}

                <hr className="my-4" />
                <div className="flex justify-between">
                  <p className="text-lg font-bold">Total</p>
                  <div className="flex flex-col items-end">
                    <p className="mb-1 text-lg font-bold">₹{cart.totalPrice}</p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ShippingScreen;
