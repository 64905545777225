import { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
  Button,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { listProducts } from "../actions/productActions";
import { listSizes } from "../actions/sizeActions";
import { useTranslation } from "react-i18next";
import { addItemToCart } from "../actions/cartActions";
import Loader from "./Loader";
import Message from "./Message";
import Pagination from "./Pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import {
  addItemToWishlist,
  getWishlistItems,
  removeItemFromWishlist,
} from "../actions/wishListActions";
import { IoChevronDownSharp } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";

const sortOptions = [
  {
    name: "Newest",
    sortBy: "latest",
    order: "desc",
    href: "#",
    current: false,
  },
  // {
  //   name: "Discount",
  //   sortBy: "discount",
  //   order: "desc",
  //   href: "#",
  //   current: false,
  // },
  {
    name: "Price: Low to High",
    sortBy: "price",
    order: "asc",
    href: "#",
    current: false,
  },
  {
    name: "Price: High to Low",
    sortBy: "price",
    order: "desc",
    href: "#",
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CategoryFilter(props) {
  const filters = [
    {
      id: "bestOfSapphire",
      name: "Only For You",
      options: [{ value: "true", label: "Only For You", checked: false }],
    },
    {
      id: "latestArrivals",
      name: "Latest Arrivals",
      options: [{ value: "true", label: "Latest Arrivals", checked: false }],
    },
    {
      id: "giftingspecial",
      name: "Gifting Special",
      options: [{ value: "true", label: "Gifting Special", checked: false }],
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routePath = useLocation();

  const [page, setPage] = useState(1);

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const queryParams = new URLSearchParams(routePath.search);
  const categoriesQuery = queryParams.get("categories") || null;
  const subCategoriesQuery = queryParams.get("subcategories") || null;
  const collectionsQuery = queryParams.get("collections") || null;
  const isLatestArrivalQuery = queryParams.get("isLatestArrival") || null;
  const genderQuery = queryParams.get("gender") || null;

  useEffect(() => {
    onTop();
    setPage(1);
  }, [
    categoriesQuery,
    collectionsQuery,
    subCategoriesQuery,
    isLatestArrivalQuery,
    genderQuery,
  ]);

  const [stockCount, setStockCount] = useState(0);
  const [qty, setQty] = useState(1);
  const [size, setSize] = useState("0");
  const [id, setId] = useState("");
  // const [sizes, setSizes] = useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [categories, setCategories] = useState([]); // Use an array for multi-select
  const [subcategories, setSubCategories] = useState([]); // Use an array for multi-select
  const [subCategory, setSubCategory] = useState([]);
  const [productFlags, setProductFlags] = useState([]);
  const [bestOfSapphire, setBestOfSapphire] = useState([]);
  const [latest, setLatest] = useState([]);
  const [gift, setGift] = useState([]);
  const [gender, setGender] = useState([]);
  const [color, setColor] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [sortBy, setSortBy] = useState("latest");
  const [order, setOrder] = useState("desc");
  const [isInitialSetupComplete, setIsInitialSetupComplete] = useState(false);
  const [sentToCart, setSentToCart] = useState(false);
  const [isCustomized, setIsCustomized] = useState(false);
  const [numberOfCustomFields, setNumberOfCustomFields] = useState(0);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [userResponses, setUserResponses] = useState([]);
  const [selectedSize, setSelectedSize] = useState(0);
  const [engravingText, setEngravingText] = useState(""); // State for engraving text
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State to track Accordion state
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(40);

  const [isOpen2, setIsOpen] = useState(false);

  const [selectedCollections, setSelectedCollections] = useState(
    collectionsQuery ? collectionsQuery.split(",") : []
  );
  const [selectedPlating, setSelectedPlating] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [selectedGender, setSelectedGender] = useState(
    genderQuery ? genderQuery.split(",") : []
  );

  // Handle changes for collections checkboxes
  const handleCollectionChange = (e) => {
    const value = e.target.value;
    setSelectedCollections((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  // Handle changes for plating checkboxes
  const handlePlatingChange = (e) => {
    const { checked, value } = e.target;

    // setSelectedPlating((prev) =>
    //   prev.includes(value)
    //     ? prev.filter((item) => item !== value)
    //     : [...prev, value]
    // );
    if (checked) {
      setSelectedPlating([value]);
    } else {
      setSelectedPlating([]);
    }
  };

  // Handle changes for gender checkboxes
  const handleGenderChange = (e) => {
    const value = e.target.value;
    setSelectedGender((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  // Handle changes for price checkboxes
  const handlePriceChange = (e) => {
    const { checked, value } = e.target;

    // Find the selected price range based on the option value
    const selectedRange = [
      {
        option: "Upto 2,000",
        minPrice: 0,
        maxPrice: 2000,
      },
      {
        option: "2,000 - 3,000",
        minPrice: 2000,
        maxPrice: 3000,
      },
      {
        option: "3,000 - 5,000",
        minPrice: 3000,
        maxPrice: 5000,
      },
      {
        option: "5,000 - 8,000",
        minPrice: 5000,
        maxPrice: 8000,
      },
      {
        option: "8,000 and Above",
        minPrice: 8000,
        maxPrice: null,
      },
    ].find((range) => range.option === value);

    if (checked) {
      setSelectedPrices([value]);
      setMinPrice(selectedRange.minPrice);
      setMaxPrice(selectedRange.maxPrice);
    } else {
      setSelectedPrices([]);
      setMinPrice(null);
      setMaxPrice(null);
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  const handlePage = (page) => {
    if (window.innerWidth > 400) {
      window.scrollTo({
        top: 325,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setPage(page);
  };

  const {
    loading: loadingFilter,
    error: errorFilter,
    menuLinks: menuFilter,
  } = useSelector((state) => state.menuLinkList);

  const {
    loading: loadingCollection,
    error: errorCollection,
    collections,
  } = useSelector((state) => state.collectionList);

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const sizeList = useSelector((state) => state.sizeList);
  const { loading: loadingSize, error: errorSize, sizes } = sizeList;

  const wishList = useSelector((state) => state.wishlist);
  const { wishlistItems } = wishList;

  const [scrolling, setScrolling] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { i18n } = useTranslation();

  let lang = i18n.language;

  const handleScroll = () => {
    if (window.innerWidth >= 1080 && window.scrollY > 300) {
      setScrolling(true);
    } else if (window.innerWidth <= 400 && window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (wishlistItems && wishlistItems?.length > 0) {
      if (wishlistItems?.some((item) => !item._id)) {
        dispatch(getWishlistItems());
      }
    }
  }, [wishlistItems]);

  useEffect(() => {
    clearFilter();

    if (props.categoriesQuery) {
      const parseCommaSeparatedArray = (param) =>
        param ? param.split(",") : [];
      const categoryArray = parseCommaSeparatedArray(props.categoriesQuery);
      setCategories(categoryArray);
    }

    if (props.subCategoriesQuery) {
      const parseCommaSeparatedArray = (param) =>
        param ? param.split(",") : [];
      const subCategoryArray = parseCommaSeparatedArray(
        props.subCategoriesQuery
      );
      setSubCategories(subCategoryArray);
    }

    if (props.collectionsQuery) {
      const parseCommaSeparatedArray = (param) =>
        param ? param.split(",") : [];
      const collectionArray = parseCommaSeparatedArray(props.collectionsQuery);
      setSelectedCollections(collectionArray);
    }

    if (props.genderQuery) {
      const parseCommaSeparatedArray = (param) =>
        param ? param.split(",") : [];
      const genderArray = parseCommaSeparatedArray(props.genderQuery);
      setSelectedGender(genderArray);
    }

    // if (props.productFlags) {
    //   const parseCommaSeparatedArray = (param) =>
    //     param ? param.split(",") : [];
    //   const collectionArray = parseCommaSeparatedArray(props.productFlags);
    //   setProductFlags(collectionArray);
    //   console.log([props.productFlags]);
    // }

    // if (props.purpose) {
    //   setSubCategory([props.purpose]);
    // }
    // setColor([props.plating]);
    // setPriceRange([props.price]);
    // setGender([props.gender]);
    // setSort([props.sort]);

    // if (props.bestOfSapphire) {
    //   setBestOfSapphire([...bestOfSapphire, props.bestOfSapphire]);
    // }

    // if (props.latestArrivals) {
    //   setLatest([...latest, props.latestArrivals]);
    // }

    // if (props.gift) {
    //   setGift([...gift, props.gift]);
    // }

    setIsInitialSetupComplete(true);
  }, [
    props,
    props.categoriesQuery,
    props.subCategoriesQuery,
    props.collectionsQuery,
    props.genderQuery
  ]);

  useEffect(() => {
    onTop();
    dispatch(listSizes());
    dispatch(
      listProducts(
        categoriesQuery,
        subCategoriesQuery,
        selectedCollections,
        isLatestArrivalQuery,
        selectedGender,
        selectedPlating,
        minPrice,
        maxPrice,
        sortBy,
        order
      )
    );
  }, [
    categoriesQuery,
    subCategoriesQuery,
    selectedCollections,
    isLatestArrivalQuery,
    selectedGender,
    selectedPlating,
    maxPrice,
    maxPrice,
    sortBy,
    order,
  ]);

  const handleQuantityChange = (value) => {
    if (value >= 1 && value <= 5) {
      setQty(value);
    }
  };

  const handleEngravingInput = (value) => {
    const lettersAndSpaces = /^[A-Za-z ]*$/; // Regex to allow only letters and spaces
    if (lettersAndSpaces.test(value)) {
      setEngravingText(value); // Update engraving text if input is valid
    }
  };

  const handleAccordionToggle = (isOpen) => {
    setIsAccordionOpen(isOpen); // Toggle Accordion state
    if (!isOpen) {
      setEngravingText(""); // Clear engraving text when Accordion is closed
    }
  };

  const clearFilter = () => {
    setSelectedCollections([]);
    setSelectedPlating([]);
    setSelectedPrices([]);
    setSelectedGender([]);
  };

  const handleAddToCart = (e, obj) => {
    e.preventDefault();
    if (qty !== 0) {
      dispatch(addItemToCart(obj));
      onClose();
      if (sentToCart) {
        navigate("/cart");
      } else {
        notify("/cart", "Item Added To Cart", toast.success);
      }
    } else {
      notify("#", "Please select size & quantity", toast.error);
    }
  };

  const handleAddToCartCustom = (e) => {
    e.preventDefault();
    dispatch(
      addItemToCart(
        1,
        "1",
        id,
        "en",
        isCustomized,
        numberOfCustomFields,
        userResponses
      )
    );
    setIsOpen(false);
    if (sentToCart) {
      navigate("/cart");
    } else {
      notify("/cart", "Item Added To Cart", toast.success);
    }
  };

  const handleAddToCart2 = (pId) => {
    dispatch(addItemToCart(1, "1", pId, "en"));
    onClose();
    notify("/cart", "Item Added To Cart", toast.success);
  };

  const addItemToWishlistHandler = (productId) => {
    dispatch(addItemToWishlist(productId));
    notify("/wishlist", "Item Added To Wishlist", toast.success);
  };

  const removeItemFromWishlistHandler = (id) => {
    dispatch(removeItemFromWishlist(id));
    notify("/wishlist", "Item Removed from Wishlist", toast.error);
  };

  const handleSizeChange = (e, qty) => {
    const selectedSize = e.target.value;
    setSize(selectedSize);
    const selectedQuantity = qty > 0 ? 1 : 0;
    setQty(selectedQuantity);
    const selectedSizeObj = sizes.find(
      (sizeObj) =>
        (i18n.language === "de" ? sizeObj.germanSize : sizeObj.indianSize) ===
        selectedSize
    );
    setStockCount(selectedSizeObj ? selectedSizeObj.countInStock : 0);
  };

  // const handleQuantityChange = (e) => {
  //   const selectedQuantity = Number(e.target.value);
  //   if (selectedQuantity > 0) {
  //     setQty(selectedQuantity);
  //   }
  // };

  const handleModalOpen = (
    sizes,
    id,
    sent,
    customized,
    customFields,
    customQuestions
  ) => {
    onOpen();
    setSentToCart(sent);
    setIsCustomized(customized);
    setNumberOfCustomFields(customFields);
    setCustomQuestions(customQuestions);
    const initialResponses = customQuestions?.map((question) => ({
      question,
      answer: "",
    }));
    setUserResponses(initialResponses);
    // setSizes(sizes);
    setId(id);
  };

  const handleModalOpen2 = (id, customized, customFields, customQuestions) => {
    setIsOpen(true);
    setSentToCart(false);
    setIsCustomized(customized);
    setNumberOfCustomFields(customFields);
    setCustomQuestions(customQuestions);
    const initialResponses = customQuestions?.map((question) => ({
      question,
      answer: "",
    }));
    setUserResponses(initialResponses);
    setId(id);
  };

  const handleResponseChange = (index, event) => {
    const newResponses = [...userResponses];
    newResponses[index].answer = event.target.value;
    setUserResponses(newResponses);
  };

  const handleCheckboxChange = (event, setStateCallback) => {
    const { value, checked } = event.target;

    setPage(1);

    setStateCallback((prevValues) => {
      if (checked) {
        return [...prevValues, value];
      } else {
        return prevValues.filter((item) => item !== value);
      }
    });
  };

  const notify = (link, content, type) =>
    type(<RouterLink to={link}>{content}</RouterLink>);

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="bg-white">
      <div>
        <Transition appear show={isOpen2} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Enter Custom Name
                    </Dialog.Title>
                    <form id="form1" onSubmit={handleAddToCartCustom}>
                      {isCustomized && (
                        <div className="flex gap-4 py-2">
                          {customQuestions.map((question, index) => (
                            <div key={index}>
                              <label className="text-base font-semibold">
                                {question}
                              </label>
                              <input
                                type="text"
                                value={userResponses[index].answer}
                                onChange={(event) =>
                                  handleResponseChange(index, event)
                                }
                                placeholder={`Enter ${question}`}
                                className="rounded-md w-full"
                                required
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      <div className="mt-4">
                        <button
                          type="submit"
                          value="form1"
                          form="form1"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <form className="mt-4 border-t border-gray-200">
                    {loadingFilter ? (
                      <Loader />
                    ) : errorFilter ? (
                      <Message type="error">{errorFilter}</Message>
                    ) : (
                      menuFilter
                        .filter(
                          (menu) =>
                            // menu.name === categoryQuery ||
                            // menu.name === productFlagsQuery
                            menu.name === "Generic"
                        )
                        .map((menu, index) => (
                          <div key={index}>
                            {menu.sublinks?.map((menuObj, index) => (
                              <Disclosure
                                key={index}
                                defaultOpen
                                as="div"
                                className="border-b border-gray-200"
                              >
                                {({ open }) => (
                                  <>
                                    <h3 className="flow-root">
                                      <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                                        <span className="text-md font-semibold text-gray-900">
                                          {menuObj.Head}
                                        </span>
                                        <span className="ml-6 flex items-center">
                                          {open ? (
                                            <MinusIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <PlusIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </span>
                                      </Disclosure.Button>
                                    </h3>
                                    <Disclosure.Panel className="py-4 px-2 bg-white">
                                      <div className="space-y-4">
                                        {menuObj.sublinkObj?.map(
                                          (option, idx) => (
                                            <div
                                              key={idx}
                                              className="flex items-center"
                                            >
                                              <input
                                                id={option.name}
                                                name={option.name}
                                                defaultValue={option.name}
                                                type="checkbox"
                                                checked={
                                                  menuObj.Head === "Purpose"
                                                    ? subCategory.includes(
                                                        option.name
                                                      )
                                                    : menuObj.Head === "Plating"
                                                    ? color.includes(
                                                        option.name
                                                      )
                                                    : menuObj.Head ===
                                                      "Category"
                                                    ? categories.includes(
                                                        option.name
                                                      )
                                                    : menuObj.Head === "Gender"
                                                    ? gender.includes(
                                                        option.name
                                                      )
                                                    : priceRange.includes(
                                                        option.name
                                                      )
                                                }
                                                onChange={(e) => {
                                                  if (
                                                    menuObj.Head === "Purpose"
                                                  ) {
                                                    handleCheckboxChange(
                                                      e,
                                                      setSubCategory
                                                    );
                                                  } else if (
                                                    menuObj.Head === "Plating"
                                                  ) {
                                                    handleCheckboxChange(
                                                      e,
                                                      setColor
                                                    );
                                                  } else if (
                                                    menuObj.Head === "Price"
                                                  ) {
                                                    handleCheckboxChange(
                                                      e,
                                                      setPriceRange
                                                    );
                                                  } else if (
                                                    menuObj.Head === "Category"
                                                  ) {
                                                    handleCheckboxChange(
                                                      e,
                                                      setCategories
                                                    );
                                                  } else if (
                                                    menuObj.Head === "Gender"
                                                  ) {
                                                    handleCheckboxChange(
                                                      e,
                                                      setGender
                                                    );
                                                  }
                                                }}
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                              />
                                              <label
                                                htmlFor={option.name}
                                                className="ml-3 text-sm text-gray-600"
                                              >
                                                {option.name}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            ))}
                          </div>
                        ))
                    )}
                    <Disclosure
                      defaultOpen
                      as="div"
                      className="border-b border-gray-200"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                              <span className="text-md font-semibold text-gray-900">
                                Collections 1
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="py-4 px-2 bg-white">
                            <div className="space-y-4">
                              {menuFilter
                                .filter(
                                  (menu) =>
                                    menu.isCollection || menu.isOccasional
                                )
                                .map((menu, idx) => (
                                  <div className="flex items-center">
                                    <input
                                      id={`filter-${menu._id}-${idx}`}
                                      name={`${menu._id}[]`}
                                      type="checkbox"
                                      value={menu.name}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, setProductFlags)
                                      }
                                      checked={productFlags.includes(menu.name)}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-${menu._id}-${idx}`}
                                      className="ml-3 text-sm text-gray-600"
                                    >
                                      {menu.name}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <div
          className={`flex w-full bg-white items-baseline justify-between border-b border-gray-200 px-2 md:px-14 py-4 ${
            scrolling ? "fixed z-10 top-[48px] md:top-[15%]" : ""
          }`}
        >
          <form className="hidden lg:flex w-full">
            <div className="flex items-center w-full gap-4">
              <h2 className="text-lg text-gray-400">FILTER BY :</h2>
              {/* Collection Filter */}
              <div className="flex gap-4">
                <Popover as="div">
                  {({ open }) => (
                    <>
                      <h3 className="flow-root">
                        <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                          <span className="text-md font-semibold text-gray-900">
                            Collections
                          </span>
                          <span className="ml-2 mr-4 flex items-center">
                            {open ? (
                              <IoIosArrowUp
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <IoIosArrowDown
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Popover.Button>
                      </h3>
                      <Popover.Panel className="absolute min-w-32 z-20 py-4 px-2 bg-white border shadow-md">
                        <div className="space-y-4">
                          {collections.map((menu, idx) => (
                            <div className="flex items-center" key={idx}>
                              <input
                                id={`filter-${menu._id}-${idx}`}
                                name={`${menu._id}[]`}
                                type="checkbox"
                                value={menu._id}
                                checked={selectedCollections.includes(menu._id)}
                                onChange={handleCollectionChange}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-${menu._id}-${idx}`}
                                className="ml-3 text-sm text-gray-600"
                              >
                                {menu.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>

              {/* Plating Filter */}
              <div className="flex gap-4">
                <Popover as="div">
                  {({ open }) => (
                    <>
                      <h3 className="flow-root">
                        <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                          <span className="text-md font-semibold text-gray-900">
                            Plating
                          </span>
                          <span className="ml-2 mr-4 flex items-center">
                            {open ? (
                              <IoIosArrowUp
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <IoIosArrowDown
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Popover.Button>
                      </h3>
                      <Popover.Panel className="absolute w-32 z-20 py-4 px-2 bg-white border shadow-md">
                        <div className="space-y-4">
                          {["silver", "gold", "rose gold"].map(
                            (plating, idx) => (
                              <div className="flex items-center" key={idx}>
                                <input
                                  id={`filter-${plating}`}
                                  name={plating}
                                  type="checkbox"
                                  value={plating}
                                  checked={selectedPlating.includes(plating)}
                                  onChange={handlePlatingChange}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-${plating}`}
                                  className="ml-3 text-sm text-gray-600 capitalize"
                                >
                                  {plating}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>

              {/* Price Filter */}
              <div className="flex gap-4">
                <Popover as="div">
                  {({ open }) => (
                    <>
                      <h3 className="flow-root">
                        <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                          <span className="text-md font-semibold text-gray-900">
                            Price
                          </span>
                          <span className="ml-2 mr-4 flex items-center">
                            {open ? (
                              <IoIosArrowUp
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <IoIosArrowDown
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Popover.Button>
                      </h3>
                      <Popover.Panel className="absolute z-20 py-4 px-2 bg-white border shadow-md">
                        <div className="space-y-4">
                          {[
                            {
                              option: "Upto 2,000",
                              minPrice: 0,
                              maxPrice: 2000,
                            },
                            {
                              option: "2,000 - 3,000",
                              minPrice: 2000,
                              maxPrice: 3000,
                            },
                            {
                              option: "3,000 - 5,000",
                              minPrice: 3000,
                              maxPrice: 5000,
                            },
                            {
                              option: "5,000 - 8,000",
                              minPrice: 5000,
                              maxPrice: 8000,
                            },
                            {
                              option: "8,000 and Above",
                              minPrice: 8000,
                              maxPrice: null,
                            },
                          ].map((price, idx) => (
                            <div className="flex items-center" key={idx}>
                              <input
                                id={`filter-${price.option}`}
                                name={price.option}
                                type="checkbox"
                                value={price.option}
                                checked={selectedPrices.includes(price.option)}
                                onChange={handlePriceChange}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-${price.option}`}
                                className="ml-3 text-sm text-gray-600"
                              >
                                {price.option}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>

              {/* Gender Filter */}
              <div className="flex gap-4">
                <Popover as="div">
                  {({ open }) => (
                    <>
                      <h3 className="flow-root">
                        <Popover.Button className="relative flex w-full items-center justify-between bg-white p-2 text-sm text-gray-400 hover:text-gray-500">
                          <span className="text-md font-semibold text-gray-900">
                            Gender
                          </span>
                          <span className="ml-2 mr-4 flex items-center">
                            {open ? (
                              <IoIosArrowUp
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <IoIosArrowDown
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Popover.Button>
                      </h3>
                      <Popover.Panel className="absolute w-32 z-20 py-4 px-2 bg-white border shadow-md">
                        <div className="space-y-4">
                          {["male", "female", "unisex"].map((gender, idx) => (
                            <div className="flex items-center" key={idx}>
                              <input
                                id={`filter-${gender}`}
                                name={gender}
                                type="checkbox"
                                value={gender}
                                checked={selectedGender.includes(gender)}
                                onChange={handleGenderChange}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-${gender}`}
                                className="ml-3 text-sm text-gray-600 capitalize"
                              >
                                {gender}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>

              {/* Button to apply filters */}
              {/* <button
                type="button"
                onClick={applyFilters}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Apply Filters
              </button> */}
            </div>
          </form>
          <div className="flex w-full md:w-auto justify-end items-center">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option, idx) => (
                      <Menu.Item key={idx}>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              setSortBy(option.sortBy);
                              setOrder(option.order);
                            }}
                            className={classNames(
                              option.current
                                ? "font-medium text-gray-900"
                                : "text-gray-500",
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm",
                              "w-full"
                            )}
                          >
                            {option.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <button
              type="button"
              className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
              onClick={() => {
                setToggle(!toggle);
                setItemsPerPage(toggle ? 40 : 40);
              }}
            >
              <span className="sr-only">View grid</span>
              <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              onClick={() => {
                setMobileFiltersOpen(true);
              }}
            >
              <span className="sr-only">Filters</span>
              <FunnelIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <main id="prodPage" className="mx-auto max-w-7xl">
          <section aria-labelledby="products-heading" className="pb-12 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>
            <div className="">
              <div className="">
                <div className="bg-white">
                  <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="sr-only">Products</h2>
                    {loading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : error ? (
                      <div>
                        <Message type="error">{error}</Message>
                      </div>
                    ) : (
                      <div
                        className={`grid mt-4 gap-x-3 gap-y-3 xl:gap-y-6 xl:gap-x-6 ${
                          toggle
                            ? "grid-cols-1 md:grid-cols-4"
                            : "grid-cols-2 md:grid-cols-5"
                        }`}
                      >
                        {currentItems?.map((product, index) => (
                          <div
                            key={index}
                            className="flex flex-col justify-between relative p-2 group/prod hover:border"
                          >
                            <RouterLink
                              to={`/product/${product.name?.replaceAll(
                                " ",
                                "-"
                              )}/${product._id}?variant=${
                                product?.variants?.length > 0 &&
                                product?.variants?.filter((variant) =>
                                  selectedPlating.length > 0
                                    ? selectedPlating.includes(variant.color)
                                    : variant.isPrimary
                                )[0]?._id
                              }`}
                            >
                              <div className="aspect-h-1 aspect-w-1 overflow-hidden bg-white">
                                {product?.variants?.length > 0 ? (
                                  <img
                                    src={
                                      product?.variants?.length > 0 &&
                                      product?.variants?.filter((variant) =>
                                        selectedPlating.length > 0
                                          ? selectedPlating.includes(
                                              variant.color
                                            )
                                          : variant.isPrimary
                                      )[0]?.images[0]
                                    }
                                    alt={product?.name}
                                    loading="lazy"
                                    className={
                                      "self-center hover:scale-125 transition-transform ease-in object-cover object-center m-auto bg-[#EBEAE5] text-center"
                                    }
                                  />
                                ) : (
                                  <div className="flex h-full w-full bg-gray-100 items-center justify-center">
                                    <span>Image</span>
                                  </div>
                                )}
                              </div>
                              <h3 className="py-1 mt-3 text-center px-2 text-base font-semibold text-gray-800 h-6 overflow-clip truncate">
                                {product.name}
                              </h3>
                              <h3 className="py-1 mt-3 text-center px-2 text-[14px] text-gray-500 h-6 overflow-clip truncate">
                                {product.description}
                              </h3>
                              <div className="flex flex-col mt-3 px-2 gap-2 md:gap-4 justify-between items-center">
                                <div className="hidden group-hover/prod:flex w-full items-center justify-center gap-1 md:gap-3 ">
                                  <p className="text-[14px] text-center text-gray-400 line-through">
                                    ₹{" "}
                                    {product.basePrice?.toLocaleString("en-IN")}
                                  </p>
                                  <p className="text-[14px] text-center md:text-base font-semibold text-[#000080]">
                                    {(
                                      ((product.basePrice - product.price) /
                                        product.basePrice) *
                                      100
                                    ).toFixed(0)}
                                    %
                                  </p>
                                </div>
                                <div className="flex w-full items-center justify-center gap-1 md:gap-3">
                                  <p className="text-sm text-center md:text-base font-bold text-gray-900">
                                    ₹ {product.price?.toLocaleString("en-IN")}
                                  </p>
                                </div>
                              </div>
                            </RouterLink>
                            <div className="w-full pt-2 bg-white hidden group-hover/prod:block">
                              <div className="flex justify-between items-center gap-1 h-[40px]">
                                <button
                                  className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                                  onClick={() => {
                                    onOpen();
                                    setSelectedProduct(product);
                                  }}
                                >
                                  Add to Cart
                                </button>
                                <button
                                  className="flex items-center justify-center w-1/4 h-full border border-[#000080] rounded"
                                  onClick={() => {
                                    wishlistItems?.some(
                                      (item) => item._id === product._id
                                    )
                                      ? removeItemFromWishlistHandler(
                                          product._id
                                        )
                                      : addItemToWishlistHandler(product._id);
                                  }}
                                >
                                  <Icon
                                    as={
                                      wishlistItems?.some(
                                        (item) => item._id === product._id
                                      )
                                        ? AiFillHeart
                                        : AiOutlineHeart
                                    }
                                    w="6"
                                    h="6"
                                    textColor="#000080"
                                    cursor="pointer"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                        <Drawer onClose={onClose} isOpen={isOpen} size="lg">
                          <DrawerOverlay />
                          <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>{`Select size and quantity`}</DrawerHeader>
                            <DrawerBody>
                              <form
                                onSubmit={(e) =>
                                  handleAddToCart(e, {
                                    name: selectedProduct?.name,
                                    image:
                                      selectedProduct?.variants?.length > 0 &&
                                      selectedProduct?.variants?.filter(
                                        (variant) =>
                                          selectedPlating.length > 0
                                            ? selectedPlating.includes(
                                                variant.color
                                              )
                                            : variant.isPrimary
                                      )[0]?.images[0],
                                    qty,
                                    sizeMen: "0",
                                    sizeWomen: `${selectedSize}`,
                                    price: selectedProduct?.price,
                                    product: selectedProduct?._id,
                                    variant:
                                      selectedProduct?.variants?.length > 0 &&
                                      selectedProduct?.variants?.filter(
                                        (variant) => variant?.isPrimary
                                      )[0]?._id,
                                    sku: selectedProduct?.sku,
                                    giftWrap: false,
                                    message: "",
                                    engraveMen: "",
                                    engraveWomen: engravingText,
                                  })
                                }
                              >
                                <Tabs isManual variant="enclosed">
                                  <TabList>
                                    <Tab>Size Chart(Indian Size)</Tab>
                                    <Tab>How to measure</Tab>
                                  </TabList>
                                  <TabPanels>
                                    <TabPanel>
                                      {isCustomized && (
                                        <div>
                                          <label className="text-base font-semibold">
                                            Enter Custom Name
                                          </label>
                                          <div className="flex gap-4 py-2">
                                            {customQuestions.map(
                                              (question, index) => (
                                                <div
                                                  key={index}
                                                  className="py-2"
                                                >
                                                  <label className="text-base font-semibold">
                                                    {question}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    value={
                                                      userResponses[index]
                                                        .answer
                                                    }
                                                    onChange={(event) =>
                                                      handleResponseChange(
                                                        index,
                                                        event
                                                      )
                                                    }
                                                    placeholder={`Enter ${question}`}
                                                    className="rounded-md w-full"
                                                    required
                                                  />
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )}

                                      <div className="mt-2 max-h-[60vh] overflow-y-auto">
                                        <table className="w-full border-collapse border border-slate-500">
                                          <tr className="text-center">
                                            <th className="w-1/3 border border-slate-300 p-1">
                                              Size
                                            </th>
                                            <th className="w-2/3 border border-slate-300 p-1">
                                              Internal Diameter(mm.)
                                            </th>
                                          </tr>
                                          {loadingSize ? (
                                            <Loader />
                                          ) : errorSize ? (
                                            <Message type="error">
                                              {errorSize}
                                            </Message>
                                          ) : (
                                            sizes?.map((size) => (
                                              <tr className="text-center">
                                                <td className="border border-slate-300 p-1">
                                                  {size?.size}
                                                </td>
                                                <td className="border border-slate-300 p-1">
                                                  {size?.internalDiameterMM} mm.
                                                </td>
                                              </tr>
                                            ))
                                          )}
                                        </table>
                                      </div>

                                      <Accordion
                                        allowMultiple
                                        onChange={() =>
                                          handleAccordionToggle(
                                            !isAccordionOpen
                                          )
                                        }
                                        my="6"
                                      >
                                        <AccordionItem>
                                          <h2>
                                            <AccordionButton>
                                              <FaRegEdit className="w-5 h-5" />
                                              <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                              >
                                                Add Engraving
                                              </Box>
                                              <AccordionIcon />
                                            </AccordionButton>
                                          </h2>
                                          <AccordionPanel pb={4}>
                                            <input
                                              type="text"
                                              maxLength="10" // Limits input to 10 characters
                                              pattern="[A-Za-z]*" // Allows only letters and spaces
                                              value={engravingText} // Controlled input
                                              className="border px-2 py-1 rounded"
                                              placeholder="Engraving text"
                                              onChange={(e) =>
                                                handleEngravingInput(
                                                  e.target.value
                                                )
                                              } // Handle engraving input
                                            />
                                            <p className="text-sm text-gray-500">
                                              Max 10 characters, letters only.
                                            </p>
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Accordion>

                                      <div className="flex items-center gap-6 my-6">
                                        <label htmlFor="quantitySelect">
                                          Quantity:
                                        </label>
                                        <div className="flex items-center">
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleQuantityChange(
                                                qty > 1 ? qty - 1 : 1
                                              )
                                            }
                                            className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                                          >
                                            -
                                          </button>
                                          <input
                                            type="text"
                                            id="quantitySelect"
                                            value={qty}
                                            onChange={(e) =>
                                              handleQuantityChange(
                                                Number(e.target.value)
                                              )
                                            }
                                            className="px-4 py-2 w-[45px] text-center text-xl bg-[#F3F3F3] focus:outline-none border-none"
                                            readOnly
                                          />
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleQuantityChange(
                                                qty < 5 ? qty + 1 : 5
                                              )
                                            }
                                            className="px-4 py-2 bg-[#F3F3F3] text-[#000080] font-bold text-xl focus:outline-none"
                                          >
                                            +
                                          </button>
                                        </div>
                                        {selectedProduct?.categories?.find(
                                          (cat) => cat.name === "Rings"
                                        ) && (
                                          <>
                                            <label htmlFor="sizeSelect">
                                              Size:
                                            </label>
                                            <Select
                                              id="sizeSelect"
                                              border="1px solid"
                                              _hover="none"
                                              className="px-4 py-2"
                                              borderColor="[#000080]"
                                              value={selectedSize}
                                              onChange={(e) =>
                                                setSelectedSize(e.target.value)
                                              }
                                              required
                                            >
                                              <option value={0}>
                                                Select Size
                                              </option>
                                              {loadingSize ? (
                                                <Loader />
                                              ) : errorSize ? (
                                                <Message type="error">
                                                  {errorSize}
                                                </Message>
                                              ) : (
                                                sizes.map((num) => (
                                                  <option
                                                    key={num?.size}
                                                    value={num?.size}
                                                  >
                                                    {num?.size}
                                                  </option>
                                                ))
                                              )}
                                            </Select>
                                          </>
                                        )}
                                      </div>
                                    </TabPanel>
                                    <TabPanel>
                                      <p className="flex w-full justify-center items-center text-lg font-bold py-8">
                                        If you dont have ring
                                      </p>
                                      <div className="grid grid-cols-2 gap-8">
                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-2.png"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          Take a small piece of string or thread
                                          or ribbon. Wrap it around your finger.
                                        </div>

                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-3.webp"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          Mark the spot where the thread meets.
                                        </div>

                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/determine-ring-size-using-paper-4.webp"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          Measure the length of the string with
                                          your ruler.
                                        </div>
                                      </div>

                                      <p className="flex w-full justify-center items-center pt-8">
                                        --------------------------------------------------------------------------------------------
                                      </p>

                                      <p className="flex w-full justify-center items-center text-lg font-bold pb-8">
                                        If you have a ring
                                      </p>
                                      <div className="grid grid-cols-2 gap-8">
                                        <div className="flex justify-center items-center">
                                          <img
                                            src="https://d8v879q3glvao.cloudfront.net/Static_images/Ring-size-guide-ruler.jpg"
                                            alt="instructions"
                                            className="h-[120px]"
                                          />
                                        </div>
                                        <div>
                                          1 : Place the ring
                                          <br />2 : Measure the inner diameter
                                          within inner edges of your ring with a
                                          ruler.
                                        </div>
                                      </div>
                                    </TabPanel>
                                  </TabPanels>
                                </Tabs>
                                <Button
                                  type="submit"
                                  isDisabled={selectedSize === 0}
                                  bgColor="#000080"
                                  textColor="white"
                                  _hover={{ bgColor: "#000090" }}
                                >
                                  Next
                                </Button>
                              </form>
                            </DrawerBody>
                          </DrawerContent>
                        </Drawer>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {loading ? (
            <></>
          ) : error ? (
            <></>
          ) : (
            <Pagination
              page={page}
              setPage={setPage}
              handlePage={handlePage}
              totalItems={products?.length}
              itemsPerPage={itemsPerPage}
            ></Pagination>
          )}
        </main>
      </div>
    </div>
  );
}
