import { IoIosCall } from "react-icons/io";
import { GrClose, GrMail } from "react-icons/gr";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import {
  Menu as ChakraMenu,
  MenuButton,
  Portal,
  MenuList,
  MenuItem,
  Flex,
  Icon,
  MenuDivider,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from "react-icons/ai";
import {
  BiMenu,
  BiMenuAltLeft,
  BiMenuAltRight,
  BiSupport,
} from "react-icons/bi";
import { authToken, logout } from "../actions/userActions";
import { listSupportDetails } from "../actions/supportFaqActions";
import {
  MdExpandLess,
  MdOutlineAdminPanelSettings,
  MdOutlineAssignmentReturn,
  MdOutlineDashboardCustomize,
  MdOutlineLocalShipping,
  MdOutlineLogout,
} from "react-icons/md";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { IoIosSearch } from "react-icons/io";
import { PiAddressBook, PiPhoneCall, PiPhoneCallLight } from "react-icons/pi";
import SearchBox from "./SearchBox";
import NavLinks from "./NavLinks";
import "./style.css";
import Message from "./Message";
import { RiListCheck2, RiLogoutBoxRLine } from "react-icons/ri";
import { Carousel } from "react-responsive-carousel";
import { getAllCoupons } from "../actions/couponActions";
import { getCartItems } from "../actions/cartActions";
import { getWishlistItems } from "../actions/wishListActions";

const usermenu = [
  {
    name: "Dashboard",
    icon: MdOutlineDashboardCustomize,
    href: "/dashboard",
    userMenu: false,
  },
  { name: "Orders", icon: RiListCheck2, href: "/orders", userMenu: true },
  {
    name: "Addresses",
    icon: PiAddressBook,
    href: "/addresses",
    userMenu: true,
  },
  {
    name: "Profile",
    icon: AiOutlineUser,
    href: "/profile",
    userMenu: true,
  },
];

const usermenu2 = [
  { name: "Track Order", href: "/orders", icon: MdOutlineLocalShipping },
  { name: "Support & FAQs", href: "/supportFAQ", icon: BiSupport },
];

const getConfigurableProps = () => ({
  showArrows: false,
  showStatus: false,
  showIndicators: false,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: false,
  autoPlay: true,
  stopOnHover: false,
  swipeable: true,
  dynamicHeight: false,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 100,
  selectedItem: 0,
  interval: 3000,
  transitionTime: 900,
  ariaLabel: "ariaLabel",
});

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [display, setDisplay] = useState("flex");
  const [open2, setOpen2] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const supportDetails = useSelector((state) => state.supportDetails);
  const {
    loading: loadingSupport,
    error: errorSupport,
    support,
  } = supportDetails;

  const couponList = useSelector((state) => state.couponList);
  const { loading: loadingCoupon, error: errorCoupon, coupons } = couponList;

  const { loading, error, menuLinks } = useSelector(
    (state) => state.menuLinkList
  );

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const wishList = useSelector((state) => state.wishlist);
  const { wishlistItems } = wishList;

  const [scrolling, setScrolling] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(getAllCoupons());
    dispatch(listSupportDetails());
    if (userInfo && userInfo.token) {
      dispatch(getCartItems());
      dispatch(getWishlistItems());
    }
  }, [dispatch]);

  useEffect(() => {
    location.pathname.split("/")[1] === `dashboard` ||
    location.pathname.split("/")[1] === "notifications" ||
    location.pathname.split("/")[1] === `orders` ||
    location.pathname.split("/")[1] === `order` ||
    location.pathname.split("/")[1] === `addresses` ||
    location.pathname.split("/")[1] === "payments" ||
    location.pathname.split("/")[1] === "profile" ||
    location.pathname.split("/")[1] === "claims"
      ? setDisplay("none")
      : setDisplay("flex");
  }, [location]);

  const logoutHandler = () => {
    dispatch(logout());
    setOpen2(false);
    navigate("/");
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`flex w-full flex-col bg-white ${
        display === "none" ? "hidden" : "flex"
      } ${scrolling ? "fixed z-20 top-0" : ""}`}
    >
      <h1 className="sr-only">
        Real Silver jewellery, online silver jewellery store, silver jewellery
        shop, silver rings, silver earrings, silver pendants, necklace in
        silver, sterling silver rings.
      </h1>
      <h2 className="sr-only">Silver jewellery store.</h2>

      <div className="whatsappbutton">
        <a
          href="https://api.whatsapp.com/send/?phone=+918828826996&text=Hello+Sairah.&type=phone_number&app_absent=0"
          target="_blank"
        >
          <img
            src="https://d8v879q3glvao.cloudfront.net/Static_images/WhatsApp.png"
            alt="wabutton"
          />
        </a>
      </div>

      <button
        className={`${
          !scrolling ? "hidden" : "block"
        } fixed bottom-6 right-6 text-white rounded-full`}
        onClick={scrollToTop}
      >
        <img className="h-12 w-12 p-1" src="../arrow.png" alt="Go To Top" />
      </button>
      <div
        className={`flex h-8 items-center justify-center text-center md:justify-between bg-[#000080] px-2 text-sm font-semibold text-white ${
          scrolling ? "md:hidden" : ""
        }`}
      >
        {/* {loadingSupport ? (
          <div>Loading</div>
        ) : errorSupport ? (
          <div>Error</div>
        ) : (
          <a
            className="hidden md:flex w-1/3 md:gap-1"
            href={`tel:${support.call}`}
          >
            <IoIosCall className="mt-[1px]" />
            <p>{support.call}</p>
          </a>
        )} */}
        {/* <div className="flex justify-center w-full md:w-1/3 text-xs md:text-sm font-semibold text-white"> */}
        <div className="flex justify-center w-full md:w-full text-xs md:text-sm font-semibold text-white">
          {loadingCoupon ? (
            <div>Loading</div>
          ) : errorCoupon ? (
            <div>Error</div>
          ) : (
            <Carousel {...getConfigurableProps()}>
              {coupons
                .filter((coupon) => coupon.isHeading)
                .map((coupon) => (
                  <div key={coupon._id}>{coupon.description}</div>
                ))}
            </Carousel>
          )}
        </div>
        {/* {loadingSupport ? (
          <div>Loading</div>
        ) : errorSupport ? (
          <div>Error</div>
        ) : (
          <a
            className="hidden md:flex w-1/3 md:justify-end md:gap-1"
            href={`mailto:${support.mail}`}
          >
            <GrMail className="mt-[3px]" />
            <p>{support.mail}</p>
          </a>
        )} */}
      </div>
      <nav
        aria-label="Top"
        className={`grid bg-white grid-cols-2 md:grid-cols-3 mx-8 ${
          scrolling ? "md:hidden" : ""
        }`}
      >
        {/* <div className="flex static md:relative w-full justify-center flex-col">
          <RouterLink to={"/"} className="flex w-full justify-center p-1">
            <span className="sr-only">Your Company</span>
            <img
              className="static md:absolute h-10 md:h-14 w-auto"
              src="../../logo/png/black/black-r@4x.png"
              alt="logo"
            />
          </RouterLink>
        </div> */}
        <div className={`hidden md:flex md:items-center text-gray-800`}>
          {/* <SearchBox /> */}
          <RouterLink
            to={`/supportFAQ`}
            className="relative hidden md:flex items-center text-sm font-bold text-gray-500 font-delius"
          >
            <PiPhoneCallLight className="h-6 w-6 flex-shrink-0 text-gray-500" />
            Contact Us
          </RouterLink>
          <span className="absolute -bottom-1 left-0 w-0 h-[1px] bg-[#8e295b] transition-all hover:w-full"></span>
        </div>
        <div className="flex static w-full justify-center flex-col">
          <RouterLink to={"/"} className="flex w-full justify-center">
            <span className="sr-only">Your Company</span>
            <img
              className="static w-full md:w-1/2"
              // src="../../logo/png/black/black-r@4x.png"
              src="../../logo/png/black/black-r@4x.png"
              alt="logo"
            />
          </RouterLink>
        </div>

        <div className="flex w-full gap-2 justify-end items-center">
          {userInfo?._id ? (
            <div className="flex w-full justify-end gap-4">
              <div className="w-full flex justify-end relative">
                <button
                  className="text-sm flex font-medium text-black relative"
                  onClick={toggleSearch}
                >
                  <span aria-hidden="true">
                    <IoIosSearch className="h-6 w-6 flex-shrink-0 text-gray-500" />
                  </span>
                </button>
                {/* Search Bar */}
                <div
                  className={`absolute right-0 -top-2 transition-all duration-500 ease-in-out bg-white overflow-hidden ${
                    searchVisible ? "w-[80%]" : "w-0"
                  }`}
                >
                  {/* <input
type="text"
className={`h-10 w-[60%] transition-opacity duration-500 ease-in-out bg-gray-100 rounded-full px-4 ${
searchVisible ? "opacity-100" : "opacity-0"
}`}
placeholder="Search..."
/> */}
                  <SearchBox
                    searchVisible={searchVisible}
                    setSearchVisible={setSearchVisible}
                  />
                </div>
              </div>
              <ChakraMenu>
                <MenuButton className="">
                  <div className="flex leading-6 text-black gap-1">
                    <p className="hidden md:block self-end">
                      Hello {userInfo.name}
                    </p>
                    <AiOutlineUser
                      className="hidden md:block text-xs h-6 w-6 flex-shrink-0 text-black"
                      aria-hidden="true"
                    />
                  </div>
                </MenuButton>
                <MenuList
                  className="text-sm font-semibold leading-6 text-black"
                  zIndex="99"
                >
                  {usermenu
                    .filter((menu) => userInfo.isAdmin || menu.userMenu)
                    .map((menu) => (
                      <MenuItem
                        as={RouterLink}
                        to={menu.href}
                        key={menu.href}
                        className="flex items-center"
                      >
                        <Icon as={menu.icon} width="5" height="5" mr="2" />
                        <h3>{menu.name}</h3>
                      </MenuItem>
                    ))}
                  <MenuDivider />
                  <MenuItem as={RouterLink} onClick={logoutHandler}>
                    <Icon as={MdOutlineLogout} width="5" height="5" mr="2" />
                    <h3>Logout</h3>
                  </MenuItem>
                </MenuList>
              </ChakraMenu>
            </div>
          ) : (
            <div className="flex w-full justify-end gap-4">
              {/* Search Button */}
              <div className="w-full flex justify-end relative">
                <button
                  className="text-sm flex font-medium text-black relative"
                  onClick={toggleSearch}
                >
                  <span aria-hidden="true">
                    <IoIosSearch className="h-6 w-6 flex-shrink-0 text-gray-500" />
                  </span>
                </button>
                {/* Search Bar */}
                <div
                  className={`absolute right-0 -top-2 transition-all duration-500 ease-in-out bg-white overflow-hidden ${
                    searchVisible ? "w-[80%]" : "w-0"
                  }`}
                >
                  {/* <input
              type="text"
              className={`h-10 w-[60%] transition-opacity duration-500 ease-in-out bg-gray-100 rounded-full px-4 ${
                searchVisible ? "opacity-100" : "opacity-0"
              }`}
              placeholder="Search..."
            /> */}
                  <SearchBox
                    searchVisible={searchVisible}
                    setSearchVisible={setSearchVisible}
                  />
                </div>
              </div>
              <div className="flow-root">
                <RouterLink
                  to="/wishlist"
                  className="text-sm flex font-medium text-black relative"
                >
                  <span aria-hidden="true">
                    <AiOutlineHeart className="h-6 w-6 flex-shrink-0 text-gray-500" />
                  </span>
                  <p
                    className={`absolute bg-[#000080] w-[20px] h-[20px] text-center text-white text-sm rounded-full top-0 left-4 z-20 ${
                      wishlistItems?.length === 0 ? "hidden" : ""
                    }`}
                  >
                    {wishlistItems?.length > 0 ? wishlistItems?.length : 0}
                  </p>
                </RouterLink>
              </div>
              <div className="flow-root">
                <RouterLink
                  to="/signup"
                  className="text-sm flex font-medium text-black relative"
                >
                  <span aria-hidden="true">
                    <AiOutlineUser className="h-6 w-6 flex-shrink-0 text-gray-500" />
                  </span>
                </RouterLink>
              </div>
              <div className="flow-root">
                <RouterLink
                  to="/cart"
                  className="text-sm flex font-medium text-black relative"
                >
                  <span aria-hidden="true">
                    <HiOutlineShoppingBag className="h-6 w-6 flex-shrink-0 text-gray-500" />
                  </span>
                  <p
                    className={`absolute bg-[#000080] w-[20px] h-[20px] text-center text-white text-sm rounded-full top-0 left-4 z-20 ${
                      cartItems?.reduce(
                        (acc, currVal) => acc + currVal.qty,
                        0
                      ) === 0
                        ? "hidden"
                        : ""
                    }`}
                  >
                    {cartItems?.reduce((acc, currVal) => acc + currVal.qty, 0)}
                  </p>
                </RouterLink>
              </div>
            </div>
          )}
          {/* <div className="mx-1 md:mx-4">
            <RouterLink
              to="/wishlist"
              className="text-sm flex lg:space-x-3 font-medium leading-6 text-black relative"
            >
              <span aria-hidden="true">
                <AiOutlineHeart
                  className="h-8 w-8 flex-shrink-0 text-black"
                  aria-hidden="true"
                />
                <p
                  className={`absolute bg-pink-600 w-[20px] h-[20px] text-center text-black rounded-full top-0 left-4 z-20 ${
                    wishlistItems?.reduce(
                      (acc, currVal) => acc + currVal.qty,
                      0
                    ) === 0
                      ? "hidden"
                      : ""
                  }`}
                >
                  {wishlistItems?.reduce(
                    (acc, currVal) => acc + currVal.qty,
                    0
                  )}
                </p>
              </span>
            </RouterLink>
          </div> */}
          <div className="mx-1 md:mx-4 block md:hidden">
            <div className="text-sm flex lg:space-x-3 font-medium leading-6 text-black ">
              <button onClick={() => setOpen2(!open2)}>
                <BiMenu
                  className="h-8 w-8 flex-shrink-0 text-black"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </nav>

      <nav
        className={`hidden md:flex w-full items-center justify-evenly gap-4 lg:px-4 bg-white ${
          scrolling ? "shadow-md" : ""
        }`}
        aria-label="Global"
      >
        <div
          className={`flex w-[16%] items-center ${scrolling ? "" : "hidden"}`}
        >
          <RouterLink to={"/"} className="flex w-full justify-center">
            <span className="sr-only">Your Company</span>
            <img
              className="w-full"
              src="../../logo/png/black/black-r@4x.png"
              alt="logo"
            />
          </RouterLink>
        </div>

        <NavLinks />

        <div className={`flex items-center ${scrolling ? "" : "hidden"}`}>
          {userInfo?._id ? (
            <ChakraMenu>
              <MenuButton className="flex text-xs leading-6 text-black">
                <AiOutlineUser
                  className="hidden md:block h-6 w-6 flex-shrink-0 text-black"
                  aria-hidden="true"
                />
              </MenuButton>
              <MenuList
                className="text-sm font-semibold leading-6 text-black"
                zIndex="99"
              >
                {usermenu
                  .filter((menu) => userInfo.isAdmin || menu.userMenu)
                  .map((menu) => (
                    <MenuItem
                      as={RouterLink}
                      to={menu.href}
                      key={menu.href}
                      className="flex items-center"
                    >
                      <Icon as={menu.icon} width="5" height="5" mr="2" />
                      <h3>{menu.name}</h3>
                    </MenuItem>
                  ))}
                <MenuDivider />
                <MenuItem as={RouterLink} onClick={logoutHandler}>
                  <Icon as={MdOutlineLogout} width="5" height="5" mr="2" />
                  <h3>Logout</h3>
                </MenuItem>
              </MenuList>
            </ChakraMenu>
          ) : (
            <ChakraMenu>
              <MenuButton className="text-xs leading-6 text-black">
                <AiOutlineUser
                  className="hidden md:block h-6 w-6 flex-shrink-0 text-black"
                  aria-hidden="true"
                />
              </MenuButton>
              <Portal>
                <MenuList
                  className="text-sm font-semibold uppercase leading-6 text-black"
                  zIndex="99"
                >
                  <RouterLink to={`/signup`}>
                    <MenuItem>Sign In</MenuItem>
                  </RouterLink>
                  <RouterLink to={`/registration`}>
                    <MenuItem>Create account</MenuItem>
                  </RouterLink>
                </MenuList>
              </Portal>
            </ChakraMenu>
          )}
          <div className="md:ml-4 flow-root">
            <RouterLink
              to="/cart"
              className="text-sm flex lg:space-x-3 font-medium leading-6 text-black relative"
            >
              <span
                className="hidden md:block h-6 w-px bg-gray-700"
                aria-hidden="true"
              />
              <AiOutlineShoppingCart
                className="h-8 w-8 flex-shrink-0 text-black"
                aria-hidden="true"
              />
              <p
                className={`absolute bg-[#000080] w-[20px] h-[20px] text-center text-white text-sm rounded-full md:top-0 left-4 z-20 ${
                  cartItems?.reduce((acc, currVal) => acc + currVal.qty, 0) ===
                  0
                    ? "hidden"
                    : ""
                }`}
              >
                {cartItems?.reduce((acc, currVal) => acc + currVal.qty, 0)}
              </p>
              <span className="sr-only">items in cart, view bag</span>
              <span
                className="hidden md:block h-6 w-px bg-gray-700"
                aria-hidden="true"
              />
            </RouterLink>
          </div>
          <div className="mx-1 md:mx-4">
            <RouterLink
              to="/wishlist"
              className="text-sm flex lg:space-x-3 font-medium leading-6 text-black relative"
            >
              <span aria-hidden="true">
                <AiOutlineHeart
                  className="h-8 w-8 flex-shrink-0 text-black"
                  aria-hidden="true"
                />
                <p
                  className={`absolute bg-[#000080] w-[20px] h-[20px] text-center text-sm text-white rounded-full md:top-0 left-4 z-20 ${
                    wishlistItems?.length === 0 ? "hidden" : ""
                  }`}
                >
                  {wishlistItems?.length > 0 ? wishlistItems?.length : 0}
                </p>
              </span>
            </RouterLink>
          </div>
        </div>
      </nav>

      <nav
        className="flex md:hidden w-full items-center justify-center px-2 py-1 lg:px-8 bg-white"
        aria-label="Global"
      >
        <SearchBox />
      </nav>

      <Transition.Root show={open2} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={setOpen2}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-70 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto px-4 py-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="w-full">
                            <div className="flex w-full flex-col">
                              <RouterLink to={"/"} className="flex w-full">
                                <span className="sr-only">Your Company</span>
                                <img
                                  className="w-2/3"
                                  src="../../logo/png/black/black-r@4x.png"
                                  alt="logo"
                                />
                              </RouterLink>
                            </div>
                          </Dialog.Title>
                          <div className="ml-3 flex h-8 items-center">
                            <button
                              type="button"
                              className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen2(false)}
                            >
                              <span className="absolute -inset-0.5" />
                              <span className="sr-only">Close panel</span>
                              <GrClose className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-4">
                          {loading ? (
                            <div></div>
                          ) : error ? (
                            <Message type="error">{error}</Message>
                          ) : (
                            <div className="flow-root">
                              {userInfo?._id ? (
                                <div>
                                  {usermenu
                                    .filter(
                                      (menu) =>
                                        userInfo.isAdmin || menu.userMenu
                                    )
                                    .map((menu) => (
                                      <div key={menu.href} className="w-full">
                                        <div className="mx-auto w-full max-w-md bg-white">
                                          <Disclosure>
                                            {({ open }) => (
                                              <Disclosure.Button className="flex w-full justify-between items-center rounded-lg px-1 py-2 text-left text-sm font-medium text-gray-900 focus:text-blue-600">
                                                <RouterLink
                                                  to={menu.href}
                                                  onClick={() =>
                                                    setOpen2(false)
                                                  }
                                                  className="flex w-full items-center gap-2"
                                                >
                                                  <span className="text-lg font-semibold">
                                                    {menu.name}
                                                  </span>
                                                </RouterLink>
                                              </Disclosure.Button>
                                            )}
                                          </Disclosure>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              ) : (
                                <div>
                                  <div className="w-full">
                                    <div className="mx-auto w-full max-w-md bg-white">
                                      <Disclosure>
                                        {({ open }) => (
                                          <Disclosure.Button className="flex w-full justify-between items-center rounded-lg px-1 py-2 text-left text-sm font-medium text-gray-900 focus:text-blue-600">
                                            <RouterLink
                                              to={"/signup"}
                                              onClick={() => setOpen2(false)}
                                              className="flex w-full items-center gap-2"
                                            >
                                              <span className="text-lg font-semibold">
                                                Sign in
                                              </span>
                                            </RouterLink>
                                          </Disclosure.Button>
                                        )}
                                      </Disclosure>
                                    </div>
                                  </div>
                                  <div className="w-full">
                                    <div className="mx-auto w-full max-w-md bg-white">
                                      <Disclosure>
                                        {({ open }) => (
                                          <Disclosure.Button className="flex w-full justify-between items-center rounded-lg px-1 py-2 text-left text-sm font-medium text-gray-900 focus:text-blue-600">
                                            <RouterLink
                                              to={"/registration"}
                                              onClick={() => setOpen2(false)}
                                              className="flex w-full items-center gap-2"
                                            >
                                              <span className="text-lg font-semibold">
                                                Create account
                                              </span>
                                            </RouterLink>
                                          </Disclosure.Button>
                                        )}
                                      </Disclosure>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {menuLinks
                                .filter(
                                  (menu) => menu.isCategory || menu.isOccasional
                                )
                                .map((menu) => (
                                  <div key={menu.name} className="w-full">
                                    <div className="mx-auto w-full max-w-md bg-white">
                                      <Disclosure>
                                        {({ open }) => (
                                          <>
                                            <Disclosure.Button className="flex w-full justify-between items-center rounded-lg px-1 py-2 text-left text-sm font-medium text-gray-900 focus:text-blue-600">
                                              {menu.isCategory && (
                                                <div className="flex w-full items-center gap-2">
                                                  <span className="text-lg font-semibold">
                                                    {menu.name}
                                                  </span>
                                                </div>
                                              )}
                                              {menu.isOccasional && (
                                                <RouterLink
                                                  to={`/product?${
                                                    menu.isCategory
                                                      ? `category=${menu.name}&hero=${menu.name}`
                                                      : `productFlags=${menu.name}&hero=${menu.name}`
                                                  }`}
                                                  onClick={() =>
                                                    setOpen2(false)
                                                  }
                                                  className="flex w-full items-center gap-2"
                                                >
                                                  <span className="text-lg font-semibold">
                                                    {menu.name}
                                                  </span>
                                                </RouterLink>
                                              )}
                                              {menu.isCategory && (
                                                <MdExpandLess
                                                  className={`${
                                                    open
                                                      ? ""
                                                      : "rotate-180 transform"
                                                  } h-5 w-5 text-gray-800`}
                                                />
                                              )}
                                            </Disclosure.Button>
                                            <Disclosure.Panel>
                                              {menu.isCategory && (
                                                <div className="flex w-full px-4 pt-4 pb-2 text-lg font-semibold text-gray-500">
                                                  <RouterLink
                                                    to={`/product?category=${menu.name}&hero=${menu.name}`}
                                                    onClick={() =>
                                                      setOpen2(false)
                                                    }
                                                    className="w-full"
                                                  >
                                                    All {menu.name}
                                                  </RouterLink>
                                                </div>
                                              )}
                                              {menu.isCategory &&
                                                menu.sublinks
                                                  .filter(
                                                    (submenu) =>
                                                      submenu.Head === "Purpose"
                                                  )
                                                  .map((submenu) => (
                                                    <>
                                                      {submenu.sublinkObj?.map(
                                                        (menuObj) => (
                                                          <div className="flex w-full px-4 pt-4 pb-2 text-lg font-semibold text-gray-500">
                                                            <RouterLink
                                                              to={`/product?category=${menu.name}&${submenu.Head}=${menuObj.name}&hero=${menu.name}`}
                                                              onClick={() =>
                                                                setOpen2(false)
                                                              }
                                                              className="w-full"
                                                            >
                                                              {menuObj.name}
                                                            </RouterLink>
                                                          </div>
                                                        )
                                                      )}
                                                    </>
                                                  ))}
                                            </Disclosure.Panel>
                                          </>
                                        )}
                                      </Disclosure>
                                    </div>
                                  </div>
                                ))}

                              <Disclosure>
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button className="flex w-full justify-between items-center rounded-lg px-1 py-2 text-left text-sm font-medium text-gray-900 focus:text-blue-600">
                                      <div className="flex w-full items-center gap-2">
                                        <span className="text-lg font-semibold">
                                          Collections
                                        </span>
                                      </div>
                                      <MdExpandLess
                                        className={`${
                                          open ? "" : "rotate-180 transform"
                                        } h-5 w-5 text-gray-800`}
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel>
                                      {menuLinks
                                        .filter((menu) => menu.isCollection)
                                        .map((menu) => (
                                          <div className="flex w-full px-4 pt-4 pb-2 text-lg font-semibold text-gray-500">
                                            <RouterLink
                                              to={`/product?productFlags=${menu.name}&hero=${menu.name}`}
                                              onClick={() => setOpen2(false)}
                                              className="w-full"
                                            >
                                              {menu.name}
                                            </RouterLink>
                                          </div>
                                        ))}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>

                              {usermenu2.map((menu) => (
                                <div key={menu.name} className="w-full">
                                  <div className="mx-auto w-full max-w-md bg-white">
                                    <Disclosure>
                                      {({ open }) => (
                                        <Disclosure.Button className="flex w-full justify-between items-center rounded-lg px-1 py-2 text-left text-sm font-medium text-gray-900 focus:text-blue-600">
                                          <RouterLink
                                            to={menu.href}
                                            onClick={() => setOpen2(false)}
                                            className="flex w-full items-center gap-2"
                                          >
                                            <span className="text-lg font-semibold">
                                              {menu.name}
                                            </span>
                                          </RouterLink>
                                        </Disclosure.Button>
                                      )}
                                    </Disclosure>
                                  </div>
                                </div>
                              ))}

                              {userInfo?._id && (
                                <div className="w-full">
                                  <div className="mx-auto w-full max-w-md bg-white">
                                    <Disclosure>
                                      {({ open }) => (
                                        <Disclosure.Button className="flex w-full justify-between items-center rounded-lg px-1 py-2 text-left text-sm font-medium text-gray-900 focus:text-blue-600">
                                          <RouterLink
                                            onClick={logoutHandler}
                                            className="flex w-full items-center gap-2"
                                          >
                                            <span className="text-lg font-semibold">
                                              Logout
                                            </span>
                                          </RouterLink>
                                        </Disclosure.Button>
                                      )}
                                    </Disclosure>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
