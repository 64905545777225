import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import PhoneInput from "react-phone-number-input";
import OtpInput from "otp-input-react";
import "react-phone-number-input/style.css";

import { auth } from "../config/firebase.config";
import Message from "../components/Message";
import {
  USER_OTP_VALIDATE_RESET,
  USER_LOGOUT,
} from "../constants/userConstants";
import { scrollToTop } from "../components/ScrollToTop";
import { login } from "../actions/userActions";

export default function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let redirect = searchParams.get("redirect") || "/";

  const [phone, setPhone] = useState("+91"); // Default to +91
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [error, setError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { error: loginError, userInfo } = userLogin;

  const userOtpValidate = useSelector((state) => state.userOtpValidate);
  const { success } = userOtpValidate;

  useEffect(() => {
    scrollToTop();
    if (userInfo && userInfo.successOTP && !success) {
      navigate("/otp?type=signup");
      dispatch({ type: USER_LOGOUT });
    } else if (userInfo && userInfo._id) {
      navigate(redirect);
    }
    dispatch({ type: USER_OTP_VALIDATE_RESET });
  }, [userInfo, redirect]);

  const sendOtp = (e) => {
    e.preventDefault();
    if (!phone) {
      setError("Please enter a valid phone number");
      return;
    }

    // Recaptcha setup
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container2",
        { size: "invisible" },
        auth
      );
    }

    // Send OTP
    signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setOtpSent(true);
      })
      .catch((err) => setError("Error sending OTP: " + err.message));
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (!otp) {
      setError("Please enter the OTP");
      return;
    }

    // OTP verification
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        setOtpVerified(true);
        console.log("OTP verified", result);

        const user = result.user;
        console.log("User signed in:", user);

        const uid = user.uid;
        console.log("User UID:", uid);

        // Now you can get the Firebase ID token
        user.getIdToken().then((idToken) => {
          console.log("Firebase ID Token:", idToken);
          // Send the token to your backend for verification
          dispatch(login(phone, idToken, uid));
        });
      })
      .catch((err) => setError("Invalid OTP: " + err.message));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!otpVerified) {
      setError("Please verify OTP first");
      return;
    }
    // else {
    //   firebase
    //     .auth()
    //     .currentUser.getIdToken(true)
    //     .then((idToken) => {
    //       // Dispatch the login action
    //       dispatch(login(phone, idToken));
    //     })
    //     .catch((error) => {
    //       console.error("Error getting Firebase ID token:", error);
    //     });
    // }
    // Dispatch login action or handle navigation
    navigate(redirect);
  };
  return (
    <div className="flex border-b justify-between gap-10 px-6 sm:px-6 lg:px-8 py-6 bg-white">
      <div className="flex flex-col items-center w-1/2 p-10 space-y-8">
        <div className="flex flex-col w-[90%]">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-3">
            Sign In
          </h2>
          <h3 className="font-semibold text-[14px]">
            Please Sign In to your SAIRAH Account using OTP.
          </h3>
        </div>
        <form className="mt-8 space-y-6 w-[90%]" onSubmit={handleLogin}>
          <div className="flex flex-col gap-4 -space-y-px rounded-md">
            <div>
              <PhoneInput
                defaultCountry="IN"
                international
                placeholder="Phone"
                value={phone}
                onChange={setPhone}
                required
              />
            </div>
            <button
              onClick={sendOtp}
              className="bg-[#000080] text-white py-2 px-4 rounded-md hover:bg-[#000090] w-full"
            >
              {otpSent ? "Resend OTP" : "Send OTP"}
            </button>
            {otpSent && (
              <>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  autoFocus
                  otpType="number"
                  disabled={false}
                  inputStyles={{
                    width: "48px", // Fixed width with !important to override any internal styles
                    height: "48px", // Fixed height
                    margin: "0 8px !important", // Add margin between inputs
                    fontSize: "24px !important", // Set a larger font size for visibility
                    textAlign: "center", // Ensure the text is centered
                    padding: "0 !important", // Eliminate padding to avoid any cropping
                    boxSizing: "border-box", // Ensure the padding is included in width and height
                    borderRadius: "6px", // Rounded edges for better UI
                    border: "1px solid #ccc !important", // Force the border to ensure it's visible
                  }}
                  className="opt-container flex w-full justify-center px-2 py-2 border border-gray-300 rounded-md"
                />
                <button
                  onClick={verifyOtp}
                  className="bg-[#000080] text-white py-2 px-4 rounded-md mt-2 w-full hover:bg-[#000090]"
                >
                  Verify OTP
                </button>
              </>
            )}
          </div>
          <button
            type="submit"
            className="bg-[#000080] text-white py-2 px-4 rounded-md hover:bg-[#000090] w-full"
            disabled={!otpVerified}
          >
            Sign In
          </button>
          {loginError && <Message type="error">{loginError}</Message>}
        </form>
        {error && <Message type="error">{error}</Message>}
      </div>
      <div className="flex justify-center items-center">
        <div className="border-l-2 border-gray-300 h-[300px]"></div>
      </div>
      <div className="flex items-center flex-col w-1/2 p-10 space-y-8">
        <div className="flex flex-col gap-10 w-[90%] mb-4">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-3">
            Create an Account
          </h2>
          <h3 className="font-semibold text-[14px] leading-10">
            Save time during checkout, view your shopping bag and saved items
            from any device and access your order history.
          </h3>
        </div>
        <div className="flex items-center w-[90%]">
          <Link
            to="/registration"
            className="flex w-full justify-center rounded-md bg-[#000080] px-3 py-2 text-sm font-semibold text-white hover:bg-[#000090] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Register
          </Link>
        </div>
      </div>
    </div>
  );
}
