import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import { useEffect, useRef, useCallback, useState } from "react";
import Webcam from "react-webcam";
import PhoneInput from "react-phone-number-input";
import Loader from "./Loader";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { serverIp } from "../config/conf";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import { claimWarranty } from "../actions/orderActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CLAIM_WARRANTY_RESET } from "../constants/orderConstants";

function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" width="full" p="1">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "pink.600",
          color: "white",
          borderColor: "pink.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function ClaimForm(props) {
  const dispatch = useDispatch();
  const webcamRef = useRef(null);

  const [showCamera, setShowCamera] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);

  const warranty = useSelector((state) => state.warranty);
  const { claimingWarranty, claimWarrantySuccess, claimWarrantyError } =
    warranty;

  const [scrollBehavior, setScrollBehavior] = useState("outside");
  const [selectedValue, setSelectedValue] = useState("");

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "orderItems",
    defaultValue: selectedValue,
    onChange: setSelectedValue,
  });

  const group = getRootProps();

  const [uploading, setUploading] = useState(false);
  const [query, setQuery] = useState("");
  const [image, setImage] = useState([]);
  const [err, setErr] = useState("");

  useEffect(() => {
    if (claimWarrantySuccess) {
      props.onClose();
      dispatch({ type: CLAIM_WARRANTY_RESET });
      setSelectedValue("");
      setQuery("");
      setImage([]);
      setErr("");
      alert(
        "You have successfully placed claim warranty form, our team will reach to you shortly."
      );
    }
    if (claimWarrantyError !== null) {
      setErr(claimWarrantyError);
    }
  }, [claimingWarranty, claimWarrantySuccess, claimWarrantyError]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        "WEBP",
        80,
        0,
        (uri) => {
          const byteString = atob(uri.split(",")[1]);
          const mimeString = uri.split(",")[0].split(":")[1].split(";")[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], { type: mimeString });

          resolve(blob);
        },
        "base64",
        400,
        400
      );
    });

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const uploadFileHandler = async (e) => {
    if (!capturedImage) {
      return;
    }

    const file = dataURItoBlob(capturedImage);
    const formData = new FormData();
    formData.append("image", file, "captured_image.jpg");

    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${serverIp}/api/uploads/single`,
        formData,
        config
      );

      if (image.length < 5) {
        setImage([...image, data]);
        setCapturedImage(null);
      } else {
        setErr("Maximum image limit reached");
      }
      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };

  const handleImageDelete = (indexToRemove) => {
    if (window.confirm("Are you sure?")) {
      if (indexToRemove >= 0 && indexToRemove < image.length) {
        const updatedImages = [...image]; // Create a copy of the array
        updatedImages.splice(indexToRemove, 1); // Remove the element at indexToRemove
        setImage(updatedImages); // Update the state with the modified array
      }
    }
  };

  const handleStartCamera = () => {
    setShowCamera(true);
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setShowCamera(false); // Close the camera after capturing
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return; // No valid drop target
    }

    const reorderedImages = [...image];
    const [reorderedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, reorderedImage);
    setImage(reorderedImages);
  };

  const handleClaimWarranty = (e) => {
    e.preventDefault();
    if (image.length > 0 && selectedValue !== "") {
      dispatch(claimWarranty(props.order_id, selectedValue, query, image));
    } else {
      setErr("Please Select Product and provide minimum 1 Image");
    }
  };

  return (
    <Modal
      size="xl"
      isOpen={props.isOpen}
      onClose={props.onClose}
      scrollBehavior={scrollBehavior}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Warranty Claim</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Select Product</p>
          <HStack {...group} flexDirection="column">
            {props.orderItems?.map((item) => {
              const radio = getRadioProps({ value: item.product });
              return (
                <RadioCard key={item.product} {...radio}>
                  <div className="flex gap-2">
                    <img
                      src={item.image}
                      className="h-14 w-14 object-cover object-center"
                      alt="prodImg"
                    />
                    <p>{item.name}</p>
                  </div>
                </RadioCard>
              );
            })}
          </HStack>

          <div className="my-4 bg-white">
            <p>Images(Add upto 5)</p>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="imageList" direction="vertical">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {image.map((img, index) => (
                      <Draggable
                        key={index}
                        draggableId={`image-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div>
                              <label
                                htmlFor="name"
                                className="block font-medium text-sm mb-1"
                              >
                                Image {index + 1}
                              </label>
                              <div className="flex p-1 gap-2 items-center">
                                <img
                                  className="w-16 h-16 object-cover object-center"
                                  src={img}
                                  alt={index}
                                />
                                <RiDeleteBin6Line
                                  onClick={() => handleImageDelete(index)}
                                  className="text-red-400"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className="flex flex-col items-start w-full">
            {!showCamera && (
              <button
                className="bg-blue-400 p-1 mb-4 text-white rounded-md"
                onClick={handleStartCamera}
              >
                Add Images
              </button>
            )}
            {showCamera && (
              <div className="pt-6">
                <Webcam
                  audio={false}
                  screenshotFormat="image/jpeg"
                  ref={webcamRef}
                />
                <button
                  className="bg-green-400 text-white p-2 m-2 rounded-md"
                  onClick={handleCapture}
                >
                  Capture
                </button>
                <button
                  className="bg-red-400 text-white p-2 m-2 rounded-md"
                  onClick={() => setShowCamera(false)}
                >
                  Close
                </button>
              </div>
            )}
            {capturedImage && (
              <div>
                <img src={capturedImage} alt="Captured" />
                <button
                  className="bg-blue-400 text-white p-2 m-2 rounded-md"
                  onClick={uploadFileHandler}
                >
                  Upload
                </button>
                <button
                  className="bg-red-400 text-white p-2 m-2 rounded-md"
                  onClick={() => setCapturedImage(null)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          <form className="bg-white" onSubmit={handleClaimWarranty}>
            <div className="mb-4">
              <label htmlFor="query" className="flex">
                <p>Query</p>
                <p className="text-red-500">*</p>
              </label>
              <textarea
                id="query"
                name="query"
                required
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="border w-full px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-300"
              ></textarea>
            </div>

            {err && err !== "" && <p className="text-red-500">{err}</p>}

            <div className="flex items-center justify-center">
              <button
                type="button"
                onClick={props.onClose}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
              >
                Close
              </button>
              <button
                type="submit"
                className="bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
