import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getUserDetails,
  updateUserProfile,
} from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate } from "react-router-dom";
import {
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_RESET,
} from "../constants/userConstants";
import { Dialog, Transition } from "@headlessui/react";
import { scrollToTop } from "../components/ScrollToTop";
import { RiDeleteBin5Line } from "react-icons/ri";
import Sidebar from "../components/Sidebar";
import "./sidenav.css";
import { MdOutlineEdit } from "react-icons/md";
import { FormLabel, Switch } from "@chakra-ui/react";
import AddressForm from "../components/AddressForm";
import AddressSelection from "../components/AddressSelection";

export default function Addresses() {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const [isOpen, setIsOpen] = useState(false);
  // const [selectedAddress, setSelectedAddress] = useState(null);

  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  // const [addresses, setAddresses] = useState([
  //   {
  //     label: "",
  //     name: "",
  //     number: "",
  //     address: "",
  //     city: "",
  //     state: "",
  //     postalCode: "",
  //     country: "India",
  //     isDefault: false,
  //   },
  // ]);

  // const [selectedLabel, setSelectedLabel] = useState("");
  // const [message, setMessage] = useState("");

  // const userDetails = useSelector((state) => state.userDetails);
  // const { loading, error, user } = userDetails;

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  // const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  // const { success } = userUpdateProfile;

  // const userDelete = useSelector((state) => state.userDelete);
  // const { success: successDelete } = userDelete;

  // useEffect(() => {
  //   scrollToTop();
  //   if (!userInfo) {
  //     navigate("/signup");
  //   } else {
  //     if (!user?.name) {
  //       dispatch(getUserDetails());
  //     } else {
  //       setName(user.name);
  //       setEmail(user.email);
  //       setPhone(user.phone);
  //       const updatedAddresses = user.addresses?.map((address) => ({
  //         ...address,
  //         uid: generateUniqueId(),
  //       }));
  //       setAddresses(updatedAddresses || []);
  //     }
  //   }

  //   if (successDelete) {
  //     navigate("/signup");
  //   }
  // }, [userInfo, navigate, user, dispatch, success, successDelete]);

  // const submitHandler = (e) => {
  //   e.preventDefault();

  //   if (password !== confirmPassword) {
  //     setMessage("Passwords do not match");
  //   } else {
  //     dispatch(
  //       updateUserProfile({
  //         id: user._id,
  //         name,
  //         email,
  //         password,
  //         addresses,
  //       })
  //     );
  //     dispatch({ type: USER_UPDATE_PROFILE_RESET });
  //     dispatch({ type: USER_DETAILS_RESET });
  //   }
  // };

  // const removeAddress = (e, uid) => {
  //   const updatedAddresses = addresses.filter((add) => add.uid !== uid);
  //   setAddresses(updatedAddresses);
  //   dispatch(
  //     updateUserProfile({
  //       id: user._id,
  //       name,
  //       email,
  //       password,
  //       addresses: updatedAddresses,
  //     })
  //   );
  //   dispatch({ type: USER_UPDATE_PROFILE_RESET });
  //   dispatch({ type: USER_DETAILS_RESET });
  // };

  // const handleSwitchToggle = (e, uid, isDefault) => {
  //   if (!isDefault) {
  //     const updatedAddresses = addresses.map((add) =>
  //       add.uid === uid
  //         ? { ...add, isDefault: true }
  //         : { ...add, isDefault: false }
  //     );
  //     setAddresses(updatedAddresses);

  //     dispatch(
  //       updateUserProfile({
  //         id: user._id,
  //         name,
  //         email,
  //         password,
  //         addresses: updatedAddresses,
  //       })
  //     );
  //     dispatch({ type: USER_UPDATE_PROFILE_RESET });
  //     dispatch({ type: USER_DETAILS_RESET });
  //   } else {
  //     const updatedAddresses = addresses.map((add) =>
  //       add.uid === uid ? { ...add, isDefault: false } : add
  //     );
  //     setAddresses(updatedAddresses);

  //     dispatch(
  //       updateUserProfile({
  //         id: user._id,
  //         name,
  //         email,
  //         password,
  //         addresses: updatedAddresses,
  //       })
  //     );
  //     dispatch({ type: USER_UPDATE_PROFILE_RESET });
  //     dispatch({ type: USER_DETAILS_RESET });
  //   }
  // };

  // const openAddressForm = (addressData) => {
  //   setSelectedAddress(addressData);
  //   setIsOpen(true);
  // };

  // const closeAddressForm = () => {
  //   setSelectedAddress(null);
  //   setIsOpen(false);
  // };

  // const saveAddress = (newAddressData) => {
  //   const updatedAddresses = !selectedAddress
  //     ? [...addresses, { ...newAddressData, uid: generateUniqueId() }]
  //     : addresses.map((address) =>
  //         address.uid === selectedAddress.uid
  //           ? { ...address, ...newAddressData }
  //           : address
  //       );

  //   setAddresses(updatedAddresses);

  //   dispatch(
  //     updateUserProfile({
  //       id: user._id,
  //       name,
  //       email,
  //       password,
  //       addresses: updatedAddresses,
  //     })
  //   );
  //   dispatch({ type: USER_UPDATE_PROFILE_RESET });
  //   dispatch({ type: USER_DETAILS_RESET });
  //   closeAddressForm();
  // };

  // const generateUniqueId = () => {
  //   return Math.floor(Date.now() * Math.random());
  // };

  return (
    <div className="">
      <Sidebar menu="Addresses" />
      <section id="content">
        <AddressSelection />
        {/* <AddressForm
          isOpen={isOpen}
          onClose={closeAddressForm}
          onSave={saveAddress}
          addressData={selectedAddress}
        />
        <main className="">
          <div className="p-2 md:p-8 border-2 border-top bg-white">
            {loading ? (
              <Loader />
            ) : error ? (
              <Message type="error">{error}</Message>
            ) : (
              <form onSubmit={submitHandler}>
                <div className="space-y-4">
                  <div className="flex flex-col border-b border-gray-900/10 pb-8">
                    <p className="text-2xl font-bold">Addresses</p>
                    <p className="">
                      Manage your saved addresses or add new once.
                    </p>
                  </div>

                  <div className="flex items-center gap-x-6 mt-8">
                    <button
                      type="button"
                      onClick={() => openAddressForm(null)}
                      className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Add Address
                    </button>
                  </div>

                  <div className="gap-6">
                    <div className="flex flex-col">
                      {addresses?.filter((add) => add.isDefault).length !==
                        0 && (
                        <h2 className="text-base mb-4 font-semibold leading-7 text-gray-900">
                          Primary Address
                        </h2>
                      )}
                      {addresses
                        ?.filter((add) => add.isDefault)
                        .map((add) => (
                          <div className="flex w-full flex-col gap-2 md:gap-4 bg-white border-2 p-4">
                            <div className="flex flex-col md:flex-row justify-between w-full gap-2">
                              <h3 className="hidden md:block text-xl font-bold">
                                {add.label}
                              </h3>
                              <div className="flex items-center gap-2 md:gap-4">
                                <button
                                  type="button"
                                  disabled={true}
                                  onClick={(e) => removeAddress(e, add.uid)}
                                  className="rounded-md cursor-not-allowed bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  <RiDeleteBin5Line />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => openAddressForm(add)}
                                  className="rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  <MdOutlineEdit />
                                </button>
                                <div className="flex items-center gap-2">
                                  <label
                                    htmlFor="isDisabled"
                                    className="text-xs truncate ... md:text-base"
                                  >
                                    Primary Address
                                  </label>
                                  <Switch
                                    id="isDisabled"
                                    onChange={(e) =>
                                      handleSwitchToggle(
                                        e,
                                        add.uid,
                                        add.isDefault
                                      )
                                    }
                                    isDisabled={add.isDefault}
                                    isChecked={add.isDefault}
                                  />
                                </div>
                              </div>
                              <h3 className="block md:hidden text-xl font-bold">
                                {add.label}
                              </h3>
                            </div>
                            <div className="flex flex-wrap w-full md:w-1/2 gap-4">
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">Name:</h3>
                                <p className="">{add.name}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">
                                  Number:
                                </h3>
                                <p className="">{add.number}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">
                                  address:
                                </h3>
                                <p className="">{add.address}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">City:</h3>
                                <p className="">{add.city}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">
                                  Pin code:
                                </h3>
                                <p className="">{add.postalCode}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">Sate:</h3>
                                <p className="">{add.state}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">
                                  Country:
                                </h3>
                                <p className="">{add.country}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>

                    <div className="flex flex-col">
                      {addresses?.filter((add) => !add.isDefault).length !==
                        0 && (
                        <h2 className="text-base mb-4 font-semibold leading-7 text-gray-900">
                          Other Address
                        </h2>
                      )}
                      {addresses
                        ?.filter((add) => !add.isDefault)
                        .map((add) => (
                          <div className="flex w-full flex-col gap-2 md:gap-4 bg-white border-2 p-4">
                            <div className="flex flex-col md:flex-row justify-between w-full gap-2">
                              <h3 className="hidden md:block text-xl font-bold">
                                {add.label}
                              </h3>
                              <div className="flex items-center gap-2 md:gap-4">
                                <button
                                  type="button"
                                  onClick={(e) => removeAddress(e, add.uid)}
                                  className="rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  <RiDeleteBin5Line />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => openAddressForm(add)}
                                  className="rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  <MdOutlineEdit />
                                </button>
                                <div className="flex items-center gap-2">
                                  <label
                                    htmlFor="isDisabled"
                                    className="text-xs truncate ... md:text-base"
                                  >
                                    Primary Address
                                  </label>
                                  <Switch
                                    id="isDisabled"
                                    onChange={(e) =>
                                      handleSwitchToggle(
                                        e,
                                        add.uid,
                                        add.isDefault
                                      )
                                    }
                                    isDisabled={add.isDefault}
                                    isChecked={add.isDefault}
                                  />
                                </div>
                              </div>
                              <h3 className="block md:hidden text-xl font-bold">
                                {add.label}
                              </h3>
                            </div>
                            <div className="flex flex-wrap w-full md:w-1/2 gap-4">
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">Name:</h3>
                                <p className="">{add.name}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">
                                  Number:
                                </h3>
                                <p className="">{add.number}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">
                                  address:
                                </h3>
                                <p className="">{add.address}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">City:</h3>
                                <p className="">{add.city}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">
                                  Pin code:
                                </h3>
                                <p className="">{add.postalCode}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">Sate:</h3>
                                <p className="">{add.state}</p>
                              </div>
                              <div className="flex items-center gap-2 md:gap-4">
                                <h3 className="text-gray-400 text-sm">
                                  Country:
                                </h3>
                                <p className="">{add.country}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </main> */}
      </section>
    </div>
  );
}
