import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getUserDetails,
  updateUserProfile,
} from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate } from "react-router-dom";
import {
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_RESET,
} from "../constants/userConstants";
import { Dialog, Transition } from "@headlessui/react";
import { scrollToTop } from "../components/ScrollToTop";
import { RiDeleteBin5Line } from "react-icons/ri";
import Sidebar from "../components/Sidebar";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export default function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [isOpen, setIsOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [err, setErr] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [addresses, setAddresses] = useState([
    {
      address: "",
      town: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
  ]);
  const [message, setMessage] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  useEffect(() => {
    scrollToTop();
    if (!userInfo) {
      navigate("/signup");
    } else {
      if (!user?.firstName) {
        dispatch(getUserDetails());
      } else {
        setName(user.firstName);
        setEmail(user.email);
        setPhone(user.phone);
        setAddresses(user.addresses || []);
      }
    }

    if (successDelete) {
      navigate("/signup");
    }

    if (success) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      setPassword("");
      setConfirmPassword("");
    }
  }, [userInfo, navigate, user, dispatch, success, successDelete]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(
        updateUserProfile({
          id: user._id,
          name,
          email,
          password,
          addresses,
        })
      );
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch({ type: USER_DETAILS_RESET });
    }
  };

  const validatePassword = (value) => {
    const minLength = 7;
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value);

    if (value.length < minLength) {
      return "Password must be at least 7 characters long, must contain at least 1 number & 1 special character.";
    } else if (!hasNumber) {
      return "Password must be at least 7 characters long, must contain at least 1 number & 1 special character.";
    } else if (!hasSpecialChar) {
      return "Password must be at least 7 characters long, must contain at least 1 number & 1 special character.";
    } else {
      return "";
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const error = validatePassword(newPassword);
    setPasswordError(error);
  };

  const handleDelete = (id) => {
    setIsOpen(false);
    dispatch(deleteUser(id));
  };

  return (
    <div>
      <Sidebar menu="Profile" />
      <section id="content">
        <main>
          <div className="p-2 md:p-4 border-2 border-top bg-white">
            {loading ? (
              <Loader />
            ) : error ? (
              <Message type="error">{error}</Message>
            ) : (
              <form onSubmit={submitHandler}>
                <div className="space-y-4">
                  <div className="border-b border-gray-900/10 pb-8">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Name
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                              type="text"
                              name="username"
                              id="username"
                              value={name}
                              contentEditable
                              disabled
                              autoComplete="username"
                              className="disabled:cursor-not-allowed block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="DeleteAcc"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Delete Account
                        </label>
                        <button
                          type="button"
                          className="rounded-md mt-2 bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => setIsOpen(true)}
                        >
                          <RiDeleteBin5Line />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Personal Information
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      Use a permanent address where you can receive mail.
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            contentEditable
                            disabled
                            value={email}
                            autoComplete="email"
                            className="disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Phone
                        </label>
                        <div className="mt-2">
                          <input
                            id="phone"
                            name="phone"
                            type="text"
                            value={phone}
                            contentEditable
                            disabled
                            autoComplete="phone"
                            className="disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">Change Password</div>

                      <div className="sm:col-span-3"></div>

                      <div className="sm:col-span-3">
                        <label htmlFor="password" className="flex">
                          <p>Password</p>
                          <p className="text-red-500">*</p>
                        </label>
                        <div className="relative rounded-md shadow-sm">
                          <input
                            id="password"
                            name="password"
                            type={showPass ? "text" : "password"}
                            autoComplete="current-password"
                            className=" block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                            <button
                              type="button"
                              onClick={() => setShowPass(!showPass)}
                              className="text-gray-500 focus:outline-none focus:text-gray-600"
                            >
                              {showPass ? (
                                <AiFillEyeInvisible
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              ) : (
                                <AiFillEye
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              )}
                            </button>
                          </div>
                        </div>
                        {passwordError && (
                          <p className="text-red-500">{passwordError}</p>
                        )}
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="confirmpassword" className="flex">
                          <p>Confirm Password</p>
                          <p className="text-red-500">*</p>
                        </label>
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          autoComplete="current-password"
                          className="relative block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder=" Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    {message && (
                      <div>
                        <Message type="error">{message}</Message>
                      </div>
                    )}
                    <div className="flex items-center gap-x-6 mt-8">
                      <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Save
                      </button>
                    </div>
                    <Transition appear show={isOpen} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={() => setIsOpen(false)}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title className="pb-2 text-lg font-bold">
                                  Deactivate account
                                </Dialog.Title>
                                <Dialog.Description className="pb-2">
                                  This will permanently deactivate your account
                                </Dialog.Description>

                                <p className="pb-2">
                                  Are you sure you want to deactivate your
                                  account? All of your data will be permanently
                                  removed. This action cannot be undone.
                                </p>

                                <button
                                  className="mx-1 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={(e) => handleDelete(user._id)}
                                >
                                  Deactivate
                                </button>
                                <button
                                  className="mx-1 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={() => setIsOpen(false)}
                                >
                                  Cancel
                                </button>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition>
                  </div>
                </div>
              </form>
            )}
          </div>
        </main>
      </section>
    </div>
  );
}
