// src/components/Cart.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getCartItems,
  addItemToCart,
  updateCartItem,
  removeItemFromCart,
  clearCart,
} from "../actions/cartActions";
import { FaRegEdit } from "react-icons/fa";
import {
  AccordionIcon,
  AccordionPanel,
  Box,
  Accordion,
  AccordionButton,
  AccordionItem,
} from "@chakra-ui/react";
import { CiSquareRemove } from "react-icons/ci";
import EngravingInput from "../components/EngravingInput";
import GiftInput from "../components/GiftInput";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [engravingText, setEngravingText] = useState(""); // State for engraving text
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State to track Accordion state
  const [selectedSize, setSelectedSize] = useState(0);

  const cart = useSelector((state) => state.cart);
  const { cartItems, successAdd, successUpdate, successRemove } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate("/signup");
    } else {
      dispatch(getCartItems());
    }
  }, [dispatch, successAdd, successUpdate, successRemove]);

  useEffect(() => {
    if (cartItems && cartItems?.length > 0) {
      if (cartItems?.some((item) => !item.product?._id)) {
        dispatch(getCartItems());
      }
    }
  }, [cartItems]);

  const handleGiftWrapChange = (
    productId,
    variantId,
    qty,
    sizeMen,
    sizeWomen,
    giftWrap,
    message,
    engraveMen,
    engraveWomen
  ) => {
    dispatch(
      updateCartItem(
        productId,
        variantId,
        qty,
        sizeMen,
        sizeWomen,
        !giftWrap,
        message,
        engraveMen,
        engraveWomen
      )
    );
  };

  const handleMessageChange = (
    productId,
    variantId,
    qty,
    sizeMen,
    sizeWomen,
    giftWrap,
    value,
    engraveMen,
    engraveWomen
  ) => {
    console.log(value);
    dispatch(
      updateCartItem(
        productId,
        variantId,
        qty,
        sizeMen,
        sizeWomen,
        giftWrap,
        value,
        engraveMen,
        engraveWomen
      )
    );
  };

  const handleAccordionToggle = (isOpen) => {
    setIsAccordionOpen(isOpen); // Toggle Accordion state
    if (!isOpen) {
      setEngravingText(""); // Clear engraving text when Accordion is closed
    }
  };

  const increaseQtyHandler = (
    productId,
    variantId,
    qty,
    sizeMen,
    sizeWomen,
    giftWrap,
    message,
    engraveMen,
    engraveWomen
  ) => {
    dispatch(
      updateCartItem(
        productId,
        variantId,
        qty + 1,
        sizeMen,
        sizeWomen,
        giftWrap,
        message,
        engraveMen,
        engraveWomen
      )
    );
  };

  const decreaseQtyHandler = (
    productId,
    variantId,
    qty,
    sizeMen,
    sizeWomen,
    giftWrap,
    message,
    engraveMen,
    engraveWomen
  ) => {
    if (qty > 1) {
      dispatch(
        updateCartItem(
          productId,
          variantId,
          qty - 1,
          sizeMen,
          sizeWomen,
          giftWrap,
          message,
          engraveMen,
          engraveWomen
        )
      );
    }
  };

  const removeFromCartHandler = (productId, variantId) => {
    dispatch(removeItemFromCart(productId, variantId));
  };

  const clearCartHandler = () => {
    dispatch(clearCart());
  };

  const handlePlaceOrder = () => {
    navigate("/shipping");
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl mb-4">Order Summary</h2>
      {cartItems?.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div className="grid grid-cols-2 gap-10">
          <div>
            {cartItems?.map((item) => (
              <div
                key={`${item.product?._id}-${item.variant}`}
                className="flex flex-col"
              >
                <div className="flex relative items-center gap-6 mt-4 py-4 border-t-2 border-gray-200">
                  <div className="flex w-[30%] items-center border aspect-1 rounded-lg">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="object-center object-cover rounded-lg"
                    />
                  </div>

                  <div className="flex flex-col text-[12px] font-semibold gap-2">
                    <h3>{item.name}</h3>
                    <div>Size: {item.sizeWomen}</div>
                    <div className="flex items-center">
                      <button
                        onClick={() =>
                          decreaseQtyHandler(
                            item.product?._id,
                            item.variant,
                            item.qty
                          )
                        }
                        className="px-2 py-1 border"
                      >
                        -
                      </button>
                      <span className="px-2">{item.qty}</span>
                      <button
                        onClick={() =>
                          increaseQtyHandler(
                            item.product?._id,
                            item.variant,
                            item.qty
                          )
                        }
                        className="px-2 py-1 border"
                      >
                        +
                      </button>
                    </div>
                    <div>₹{item.price * item.qty}</div>
                    {item?.product?.canEngrave && (
                      // <div>
                      //   <input
                      //     type="text"
                      //     maxLength="10" // Limits input to 10 characters
                      //     pattern="[A-Za-z]*" // Allows only letters and spaces
                      //     value={item.engraveWomen} // Controlled input
                      //     className="border px-2 py-1 rounded"
                      //     placeholder="Engraving text"
                      //     onChange={(e) =>
                      //       handleEngravingInput(
                      //         item?.product?._id,
                      //         item?.variant,
                      //         item?.qty,
                      //         item?.sizeMen,
                      //         item?.sizeWomen,
                      //         item?.giftWrap,
                      //         item?.message,
                      //         item?.engraveMen,
                      //         e.target.value
                      //       )
                      //     } // Handle engraving input
                      //   />
                      //   <p className="text-sm text-gray-500">
                      //     Max 10 characters, letters only.
                      //   </p>
                      // </div>
                      // <Accordion
                      //   allowMultiple
                      //   onChange={() => handleAccordionToggle(!isAccordionOpen)}
                      // >
                      //   <AccordionItem>
                      //     <h2>
                      //       <AccordionButton>
                      //         <FaRegEdit className="w-5 h-5" />
                      //         <Box as="span" flex="1" textAlign="left">
                      //           Engraving{" "}
                      //           {item?.product?.gender === "female"
                      //             ? `- ${item?.engraveWomen}`
                      //             : `- ${item?.engraveMen}`}
                      //         </Box>
                      //         <AccordionIcon />
                      //       </AccordionButton>
                      //     </h2>
                      //     <AccordionPanel pb={4}>
                      //       <EngravingInput key={item.id} item={item} />
                      //     </AccordionPanel>
                      //   </AccordionItem>
                      // </Accordion>

                      <EngravingInput key={item.id} item={item} />
                    )}
                    {/* <div>Save For Later</div> */}

                    {/* <Accordion
                    allowMultiple
                    onChange={() => handleAccordionToggle(!isAccordionOpen)}
                  >
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <FaRegEdit className="w-5 h-5" />
                          <Box as="span" flex="1" textAlign="left">
                            {item?.message !== ""
                              ? `Gift Wrap - ${item?.message}`
                              : `Add Gift Wrap`}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <div>
                          {item.giftWrap && (
                            <GiftInput key={item.id} item={item} />
                          )}
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion> */}
                  </div>
                  <button
                    onClick={() =>
                      removeFromCartHandler(item.product?._id, item.variant)
                    }
                    className="absolute top-4 right-4 px-2 py-1"
                  >
                    <CiSquareRemove className="w-6 h-6" />
                  </button>
                </div>
                <GiftInput key={item.id} item={item} />
              </div>
            ))}
          </div>
          <div className="flex w-full flex-col gap-6">
            <div className="flex bg-gray-100 p-4 items-center gap-4 justify-center mb-4">
              <img src="../freedel.png" />
              <p>
                Yay! <strong>No Delivery Charge</strong> on this order.
              </p>
            </div>

            <div className="flex flex-col gap-4 relative mb-4">
              <div className="font-bold">Have a Coupon?</div>
              <input
                className="p-2 bg-gray-100 border-1 border-gray-300 rounded"
                type="text"
                placeholder="Enter Coupon Code"
              />
              <button className="absolute right-4 top-[50px] text-sm text-[#000080] font-bold">
                APPPLY
              </button>
            </div>

            <div className="flex flex-col gap-6">
              <h2 className="font-bold">{`PRICE DETAILS (${
                cartItems?.length + 1
              } ITEMS)`}</h2>
              <div className="flex justify-between text-sm text-gray-500">
                <div>Total MRP</div>
                <div>
                  {cartItems?.reduce(
                    (acc, item) => acc + item.price * item.qty,
                    0
                  )}
                </div>
              </div>
              <div className="flex justify-between text-sm text-gray-500">
                <div>Discount on MRP</div>
                <div>0</div>
              </div>
              <div className="flex justify-between text-sm text-gray-500">
                <div>Coupon Discount</div>
                <div>0</div>
              </div>
              <div className="flex justify-between text-sm text-gray-500">
                <div>Delivery Charge</div>
                <div>0</div>
              </div>

              <div className="flex justify-between border-t-2 border-gray-200 font-bold pt-4">
                <div>Total Amount</div>
                <div>
                  {" "}
                  {cartItems?.reduce(
                    (acc, item) => acc + item.price * item.qty,
                    0
                  )}
                </div>
              </div>

              <div className="flex w-full">
                <button
                  onClick={() => handlePlaceOrder()}
                  className="w-full bg-[#000080] flex text-white p-4 font-semibold gap-4 justify-center items-center"
                >
                  Place Order
                  <img src="../arright.png" alt="arr" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
