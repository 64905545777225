import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  getProductSetById,
  updateProductSet,
} from "../actions/productSetAction";
import { listProducts } from "../actions/productActions"; // To get product list
import { PRODUCT_SET_UPDATE_RESET } from "../constants/productSetConstants";
import { serverIp } from "../config/conf";
import axios from "axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import Loader from "../components/Loader";

const ProductSetEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [uploading, setUploading] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);

  const productSetDetails = useSelector((state) => state.productSetDetails);
  const { loading, error, productSet } = productSetDetails;

  const productSetUpdate = useSelector((state) => state.productSetUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productSetUpdate;

  const productList = useSelector((state) => state.productList);
  const {
    loading: loadingProdList,
    error: errorProdList,
    products,
  } = productList;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_SET_UPDATE_RESET });
      navigate("/dashboard/set");
    } else {
      if (!productSet.name || productSet._id !== id) {
        dispatch(getProductSetById(id));
      } else {
        setName(productSet.name);
        setImage(productSet.image);
        setSelectedProducts(
          productSet.products.map((product) => ({
            value: product._id,
            label: product.name,
          }))
        );
      }
    }
    dispatch(
      listProducts(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        "latest",
        "desc"
      )
    );
  }, [dispatch, navigate, id, productSet, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    const productIds = selectedProducts.map((product) => product.value); // Ensure only product IDs are passed
    dispatch(updateProductSet(id, { name, image, products: productIds }));
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${serverIp}/api/uploads/single`,
        formData,
        config
      );
      setImage(data);
      setUploading(false);
    } catch (err) {
      console.error(err);
      setUploading(false);
    }
  };
  const handleImageDelete = () => {
    if (window.confirm("Are you sure?")) {
      setImage("");
    }
  };

  const productOptions = products?.map((product) => ({
    value: product._id, // Ensure ObjectId is used here
    label: product.name, // The name of the product for display
  }));

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-3xl font-bold mb-6">Edit Product Set</h1>

      {loadingUpdate && <p className="text-blue-500">Updating...</p>}
      {errorUpdate && <p className="text-red-500">{errorUpdate}</p>}

      {loading ? (
        <p className="text-blue-500">Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <form onSubmit={submitHandler} className="space-y-6">
          {/* Name Input */}
          <div>
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter product set name"
            />
          </div>

          {/* Image Input */}
          {/* <div>
            <label className="block text-gray-700">Image</label>
            <input
              type="text"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter product set image URL"
            />
            <input
                        id="dropzone-file1"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => uploadFileHandler(e, "Desktop")}
                      />
                      {uploading && <Loader />}
          </div> */}
          <div className="flex items-center justify-center w-full">
            <label
              htmlFor="dropzone-file1"
              className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 mb-3 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> desktop
                  banner
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX 1)
                </p>
              </div>
              <input
                id="dropzone-file1"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={(e) => uploadFileHandler(e)}
              />
              {uploading && <Loader />}
            </label>
          </div>
          <div className="mb-4">
            <label htmlFor="name" className="block font-medium text-sm mb-1">
              Desktop Image
            </label>
            <div className="flex">
              <img className="w-1/5" src={image} alt={image} />
              <p className="w-3/5">{image?.split("\\").pop()}</p>
              <RiDeleteBin6Line
                onClick={() => handleImageDelete()}
                className="w-1/5"
              />
            </div>
          </div>

          {/* Products Multi-Select Input */}
          <div>
            <label className="block text-gray-700">Products</label>
            <Select
              isMulti
              value={selectedProducts}
              onChange={(selected) => setSelectedProducts(selected)}
              options={productOptions}
              className="mt-1 block w-full"
              placeholder="Select products..."
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
          >
            Update Product Set
          </button>
        </form>
      )}
    </div>
  );
};

export default ProductSetEdit;
