import { useEffect, useState } from "react";
import { listProductSets } from "../actions/productSetAction";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick"; // Import the slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md"; // Icons for next/prev arrows
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "./Loader";
import Message from "./Message";
import { Icon, useDisclosure } from "@chakra-ui/react";
import {
  addItemToWishlist,
  getWishlistItems,
  removeItemFromWishlist,
} from "../actions/wishListActions";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: false,
  // showIndicators: true,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: true,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: true,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 100,
  selectedItem: 0,
  interval: 2000,
  transitionTime: 800,
  swipeScrollTolerance: 5,
});

export default function Collections() {
  const dispatch = useDispatch();

  const notify = (link, content, type) =>
    type(<RouterLink to={link}>{content}</RouterLink>);

  const productSetList = useSelector((state) => state.productSetList);
  const { loading, error, productSets } = productSetList;

  const wishList = useSelector((state) => state.wishlist);
  const { wishlistItems } = wishList;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    dispatch(listProductSets());
    dispatch(getWishlistItems());
  }, [dispatch]);

  const settings1 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const removeItemFromWishlistHandler = (id) => {
    dispatch(removeItemFromWishlist(id));
    notify("/wishlist", "Item Removed from Wishlist", toast.error);
  };

  const addItemToWishlistHandler = (productId) => {
    dispatch(addItemToWishlist(productId));
    notify("/wishlist", "Item Added To Wishlist", toast.success);
  };

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-12">
      <p className="text-center text-xl font-semibold">MASTERPIECE BY SAIRAH</p>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <div className="w-full min-h-[20vh] md:min-h-[70vh] bg-white">
          <Slider {...settings1}>
            {productSets.map((productSet, index) => (
              <div>
                <div
                  key={index}
                  className="flex flex-col md:flex-row w-full items-center justify-center py-12 gap-10"
                >
                  <div className="flex items-center justify-center w-full md:w-1/2">
                    <div className="w-1/2">
                      {/* <Carousel {...getConfigurableProps()}> */}
                      <Slider {...settings2}>
                        {productSet?.products?.map((product, index) => (
                          <div
                            key={index}
                            className="flex h-full flex-col justify-between relative p-2 group/prod"
                          >
                            <RouterLink
                              to={`/product/${product.name?.replaceAll(
                                " ",
                                "-"
                              )}/${product._id}?variant=${
                                product?.variants?.length > 0 &&
                                product?.variants?.filter(
                                  (variant) => variant.isPrimary
                                )[0]?._id
                              }`}
                            >
                              <div className="aspect-h-1 aspect-w-1 overflow-hidden bg-white">
                                {product?.variants?.length > 0 ? (
                                  <img
                                    src={
                                      product?.variants?.length > 0 &&
                                      product?.variants?.filter(
                                        (variant) => variant.isPrimary
                                      )[0]?.images[0]
                                    }
                                    alt={product?.name}
                                    loading="lazy"
                                    className={
                                      "self-center hover:scale-125 transition-transform ease-in object-cover object-center m-auto bg-[#EBEAE5] text-center"
                                    }
                                  />
                                ) : (
                                  <div className="flex h-full w-full bg-gray-100 items-center justify-center">
                                    <span>Image</span>
                                  </div>
                                )}
                              </div>
                              <h3 className="py-1 mt-3 text-center px-2 text-base font-semibold text-gray-800 h-6 overflow-clip truncate">
                                {product.name}
                              </h3>
                              <h3 className="py-1 mt-3 text-center px-2 text-[14px] text-gray-500 h-6 overflow-clip truncate">
                                {product.description}
                              </h3>
                              <div className="flex flex-col mt-3 px-2 gap-2 md:gap-4 justify-between items-center">
                                <div className="hidden group-hover/prod:flex w-full items-center justify-center gap-1 md:gap-3 ">
                                  <p className="text-[14px] text-center text-gray-400 line-through">
                                    ₹{" "}
                                    {product.basePrice?.toLocaleString("en-IN")}
                                  </p>
                                  <p className="text-[14px] text-center md:text-base font-semibold text-[#000080]">
                                    {(
                                      ((product.basePrice - product.price) /
                                        product.basePrice) *
                                      100
                                    ).toFixed(0)}
                                    %
                                  </p>
                                </div>
                                <div className="flex w-full items-center justify-center gap-1 md:gap-3">
                                  <p className="text-sm text-center md:text-base font-bold text-gray-900">
                                    ₹ {product.price?.toLocaleString("en-IN")}
                                  </p>
                                </div>
                              </div>
                            </RouterLink>
                            <div className="w-full pt-2 bg-white hidden group-hover/prod:block">
                              <div className="flex justify-between items-center gap-1 h-[40px]">
                                <button
                                  className="w-3/4 h-full bg-[#000080] rounded text-center self-center text-white text-sm font-medium"
                                  onClick={() => {
                                    onOpen();
                                    setSelectedProduct(product);
                                  }}
                                >
                                  Add to Cart
                                </button>
                                <button
                                  className="flex items-center justify-center w-1/4 h-full border border-[#000080] rounded"
                                  onClick={() => {
                                    wishlistItems?.some(
                                      (item) => item._id === product._id
                                    )
                                      ? removeItemFromWishlistHandler(
                                          product._id
                                        )
                                      : addItemToWishlistHandler(product._id);
                                  }}
                                >
                                  <Icon
                                    as={
                                      wishlistItems?.some(
                                        (item) => item._id === product._id
                                      )
                                        ? AiFillHeart
                                        : AiOutlineHeart
                                    }
                                    w="6"
                                    h="6"
                                    textColor="#000080"
                                    cursor="pointer"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>

                  {/* Main Product Set Image Section */}
                  <div className="flex flex-col gap-4 items-center justify-center w-full md:w-1/2">
                    <img
                      src={productSet.image} // Replace with the main product set image
                      alt="Main Product Set"
                      className="max-w-full"
                    />
                    <h2 className="text-[18px] font-[700]">{productSet.name}</h2>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <MdNavigateNext
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingLeft: "20px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <MdNavigateBefore
      className={className}
      style={{
        color: "gray",
        fontSize: "45px",
        height: "55px",
        width: "55px",
        paddingRight: "20px",
      }}
      onClick={onClick}
    />
  );
}
