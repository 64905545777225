import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCartItem } from "../actions/cartActions";

const EngravingInput = ({ item }) => {
  const dispatch = useDispatch();
  const [engraveMenText, setEngraveMenText] = useState(item.engraveMen || "");
  const [engraveWomenText, setEngraveWomenText] = useState(item.engraveWomen || "");

  const handleEngravingInputChange = (e, gender) => {
    const value = e.target.value;
    const lettersAndSpaces = /^[A-Za-z ]*$/; // Regex to allow only letters and spaces
    if (lettersAndSpaces.test(value)) {
      if (gender === "male") {
        setEngraveMenText(value); // Update local state for men
      } else {
        setEngraveWomenText(value); // Update local state for women
      }
    }
  };

  const handleUpdateClick = (gender) => {
    if (gender === "male") {
      dispatch(
        updateCartItem(
          item?.product?._id,
          item?.variant,
          item?.qty,
          item?.sizeMen,
          item?.sizeWomen,
          item?.giftWrap,
          item?.message,
          engraveMenText, // Use the local state for men's engraving
          item?.engraveWomen // Keep the existing engraving for women
        )
      );
    } else {
      dispatch(
        updateCartItem(
          item?.product?._id,
          item?.variant,
          item?.qty,
          item?.sizeMen,
          item?.sizeWomen,
          item?.giftWrap,
          item?.message,
          item?.engraveMen, // Keep the existing engraving for men
          engraveWomenText // Use the local state for women's engraving
        )
      );
    }
  };

  return (
    <div>
      <div className="flex gap-4">
        {item?.product?.gender !== "female" && (
          <div>
            <input
              type="text"
              maxLength="10" // Limits input to 10 characters
              value={engraveMenText} // Controlled input using local state for men
              className="border px-2 py-1 rounded text-sm"
              placeholder="Engraving text"
              onChange={(e) => handleEngravingInputChange(e, "male")} // Handle local input change for men
            />
            <button
              onClick={() => handleUpdateClick("male")}
              className="bg-blue-500 text-sm text-white px-2 py-1.5 mx-1 rounded"
            >
              Update
            </button>
          </div>
        )}
        {item?.product?.gender !== "male" && (
          <div>
            <input
              type="text"
              maxLength="10" // Limits input to 10 characters
              value={engraveWomenText} // Controlled input using local state for women
              className="border px-2 py-1 rounded text-sm"
              placeholder="Engraving text"
              onChange={(e) => handleEngravingInputChange(e, "female")} // Handle local input change for women
            />
            <button
              onClick={() => handleUpdateClick("female")}
              className="bg-blue-500 text-sm text-white px-2 py-1.5 mx-1 rounded"
            >
              Update
            </button>
          </div>
        )}
      </div>
      <p className="text-sm text-gray-500">Max 10 characters, letters only.</p>
    </div>
  );
};

export default EngravingInput;
